import React, { useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import Cities from "../cities.json"

export default function Search(props) {
	const cities = Cities;
	let { categories } = props;
	const navigate = useNavigate();
	const customer = JSON.parse(localStorage.getItem('login-token'));
	const [search, setSearch] = useState({ search: '', category: 0, city: '',customer:(customer!==null)?customer.id:0});

	const handleChange = (event) => {
		event.preventDefault();
		event.persist();
		let name = event.target.name;
		let value = event.target.value;
		setSearch({ ...search, [name]: value, })
	}

	const handleCity = (event) => {
    let city = event.value;
    setSearch({ ...search, ["city"]: city, })
  }

	const handleSubmit = (event) => {
		event.preventDefault();
		event.persist();
		if (search.category === 0 && search.search == '' && search.city == '') {
			let msg = "Please select atleast one input.";
			toast.error(msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, })
		} else {
			navigate('/shops', { state: search });
		}
	}

	return (
		<>
			<ToastContainer />
			<section className="w3l-search-form-3-main">
				<div className="search-form-3">
					<div className="wrapper">
						<div className="section-width">
							<form className="search-3-gd" onSubmit={handleSubmit}>
								<div className="d-flex grids-icon">
									<span className="fa fa-text-height" aria-hidden="true"></span>
									<input type="search" name="search" onChange={handleChange} placeholder="Enter Pincode here.." max={6} accept="numeric" />
								</div>
								<div className="d-flex grids-icon grids-icon-2">
									<span className="fa fa-tags" aria-hidden="true"></span>
									<div className="input-group-btn">
										<select className="btn btn-default" name="category" onChange={handleChange} style={{ textAlign: 'justify' }}>
											<option defaultValue="all" value={'all'}>Select Category</option>
											{categories.map((category) => {
												return (
													<option key={category.id} value={category.id} >{category.name}</option>
												)
											})}
										</select>
									</div>
								</div>
								<div className="d-flex grids-icon grids-icon-2">
									<span className="fa fa-map-marker" aria-hidden="true"></span>
									<div className="input-group-btn">
										<Select
											className="basic-single"
											classNamePrefix="select"
											onChange={handleCity}
											defaultValue={null}
											isDisabled={false}
											isLoading={true}
											isClearable={true}
											isRtl={false}
											isSearchable={true}
											name="city"
											options={cities}
											placeholder="Search By City"
										/>
									</div>
								</div>
								<button type="submit" className="btn button-eff"><span className="fa fa-search"
									aria-hidden="true"></span>Search</button>
							</form>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
