import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate, useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import default_image from '../default-image.png';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import InfiniteScroll from "react-infinite-scroll-component";
import Select from 'react-select';
import Cities from "../cities.json"

function Shops() {
  const home = useLocation();
  const cities = Cities;
  const navigate = useNavigate();
  const [pageloading, setpageLoading] = useState(false);
  const [shops, setShops] = useState(0);
  const [count, setcount] = useState(0);
  const [counter, setCounter] = useState(0)
  const [categories, setCategories] = useState(null);
  const [search, setSearch] = useState('');
  const [searchCity, setCity] = useState('');
  const [searchCat, findCategory] = useState(0);
  const [cat_name, setCatName] = useState("All");
  const [customer,setCustomer] = useState(0);

  useEffect(() => {
    const fetchdata = async () => {
      setpageLoading(true);
      var data = {};
      if (localStorage.getItem('login-token') !== null) {
        const customer = JSON.parse(localStorage.getItem('login-token'));
        data = { category: 0, search: '', city: '',customer:customer.id}
        setCustomer(customer.id);
      } else {
        data = { category: 0, search: '', city: '',customer:0}
      }
      
      if (home.state !== null) {
        data = home.state;
      }

      await Api.post('shops/shop', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          setpageLoading(false);
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setShops(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setCategories(result.data.data.categories);
            setShops(result.data.data.shops);
            setcount(result.data.data.count);
            // set deafaul //
            setSearch(data.search);
            findCategory(data.category);
            setCity(data.city);
          }
        });
    };
    fetchdata();
  }, []);


  const handleCategory = (event) => {
    event.preventDefault();
    event.persist();
    let category = event.target.dataset.category;
    findCategory(category);
    getdata(category, search, searchCity);
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.persist();
    let category = event.target.value;
    findCategory(category);
    getdata(category, search, searchCity);
  }

  const handleCity = (event) => {
    //event.preventDefault();
    //event.persist();
    let city = event.value;
    setCity(city);
    getdata(searchCat, search, city);
  }

  const changeSearch = (event) => {
    event.persist();
    setSearch(event.target.value);
  }

  const handleSearch = (event) => {
    event.preventDefault();
    event.persist();
    getdata(searchCat, search, searchCity);
  }

  const resetSearch = (event) => {
    event.preventDefault();
    event.persist();
    setSearch('')
    if (search !== '')
      getdata(searchCat, '', searchCity);
  };

  const getdata = async (category, search, searchCity, offset = 0) => {
    setpageLoading(true);
    const data = { category: category, search: search, city: searchCity ,customer:customer}
    await Api.post('shops/category_shop/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        setpageLoading(false);
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          setShops(0);
          setcount(0);
          setCatName(result.data.data.category);
          toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          //let data = shops.concat();
          setShops(result.data.data.shops);
          setcount(result.data.data.count);
          setCatName(result.data.data.category);
          if (result.data.data.categories !== null)
            setCategories(result.data.data.categories);
          setCounter(0);
          document.getElementById("scrollableDiv").scrollTo(0, 0);
          window.scrollTo(0, 0);
        }
      });
  };

  const fetchmore = async () => {
    let offset = counter + 1;
    const data = { category: searchCat, search: search, city: searchCity ,customer:customer}
    setTimeout(async () => {
      await Api.post('shops/category_shop/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            //setpageLoading(false);
            let data = shops.concat(result.data.data.shops);
            setShops(data);
            setCounter(offset);
          }
        });
    }, 1500);
  }

  const moveSingle = (shop_id) => {
    navigate('/single', { state: { id: shop_id } });
  }

  const addFavorite = async (event, shop_id) => {
		if (localStorage.getItem('login-token') !== null) {
			const customer = JSON.parse(localStorage.getItem('login-token'));
			let data = { customer_id: customer.id, franchisee_id: shop_id };
			await Api.post('customers/favorites', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
				.then(res => {
					const result = jwt_decode(res.data);
					const status = result.data.status;
					if (status === 'failure') {
						toast.error(result.data.error, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					} else if (status === 'success') {
						if (result.data.action === "store")
							event.target.className = "fa fa-heart";
						if (result.data.action === "remove")
							event.target.className = "fa fa-heart-o";
						toast.success(result.data.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					}
				});
		} else {
			toast.error('Please login to add favorite !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
		}
	}

  return (
    <>
      <Header />
      <ToastContainer />
      <section className="w3l-products-page w3l-blog-single w3l-products-4">
        <div className="single blog">
          <div className="wrapper">
            <h3 className="title-main">{cat_name} Shops</h3>
            <div className="d-grid grid-colunm-2 grid-colunm">

              <div className="right-side-bar">
                <aside className="posts p-4 border">
                  <h3 className="aside-title">All Categories</h3>
                  <ul className="category">
                    <li><a href={"#!"} data-category={'All'} onClick={handleCategory}><span className="fa fa-asterisk"></span>All</a>
                    </li>
                    {
                      categories && categories.map((category, catIndex) =>
                        <li key={catIndex}><a href='#.' data-category={category.id} onClick={handleCategory}><span className={category.icon}></span>{category.name} <label>
                          ({category.count})</label></a>
                        </li>)
                    }
                  </ul>
                </aside>
                <aside className="posts p-4 border">
                  <h3 className="aside-title">Premium Shops</h3>
                  <div className="posts-grid">
                    <a href="#.">
                      <img src="assets/images/b1.jpg" alt="" className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/b2.jpg" alt="" className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/b3.jpg" alt="" className="img-responsive img-thumbnail" />
                    </a>
                  </div>
                </aside>
                <aside className="">
                  <h3 className="aside-title mb-3">Advertisement</h3>
                  <img src="assets/images/screen.jpg" alt="" className="img-fluid img-responsive" />
                </aside>
              </div>
              {/*right side bar*/}

              <div className="tab-content text-left">
                <aside className="top-border d-flex">
                  {/* <h3 className="aside-title mb-3">Showing 1–{shops.length} of {count} results</h3> */}
                  <form className="form-inline search-form" onSubmit={handleSearch}>
                    <div className="input-group">
                      <div className="input-group-prepend" style={{ height: "38px", borderColor: '#f85c70', color: "white" }}>
                        <span className="input-group-text" style={{ background: '#f85c70' }}>
                          <button type='reset' className="fa fa-repeat" style={{ color: '#ffffff', border: 'none', outline: 'none' }} onClick={resetSearch}></button>
                        </span>
                      </div>
                      <input type="text" className="form-control" style={{ padding: 'unset' }} placeholder="Search shop by Pincode" onChange={changeSearch} value={search} />
                      <div className="input-group-append" style={{ height: "38px", borderColor: '#f85c70', color: "white" }}>
                        <div className="input-group-text" style={{ background: '#f85c70' }}>
                          <button type='submit' className="fa fa-search" style={{ color: '#ffffff', border: 'none', outline: 'none' }}></button>
                        </div>
                      </div>
                    </div>
                  </form>

                  {/* <form className="form-inline search-form">
                    <div className="input-group">
                      <div className="input-group-prepend" style={{ height: "38px", borderColor: '#f85c70', color: "white" }}>
                        <span className="input-group-text" style={{ background: '#f85c70' }}>
                          <button type='reset' className="fa fa-repeat" style={{ color: '#ffffff', border: 'none', outline: 'none' }} onClick={resetSearch}></button>
                        </span>
                      </div>
                      <input type="text" className="form-control" style={{ padding: 'unset' }} placeholder="Search shop by Name" onChange={changeSearch} value={search} />
                      <div className="input-group-append" style={{ height: "38px", borderColor: '#f85c70', color: "white" }}>
                        <div className="input-group-text" style={{ background: '#f85c70' }}>
                          <button type='submit' className="fa fa-search" style={{ color: '#ffffff', border: 'none', outline: 'none' }}></button>
                        </div>
                      </div>
                    </div>
                  </form> */}

                  {/* <div className="input-group-btn">
                    <select className="btn btn-default" name="ext" value={searchCity} onChange={handleCity}  style={{ textAlign: 'justify' }}>
                      <option value="">Sort By City</option>
                    </select>
                  </div> */}
                  {/* {console.log(homeCity)} */}
                  <div className="input-group-btn">
                    <Select
                      className="basic-single"
                      classNamePrefix="select"
                      onChange={handleCity}
                      defaultValue={(home.state && home.state.city !== null) ? { label: home.state.city, value: home.state.city } : null}
                      isDisabled={false}
                      isLoading={true}
                      isClearable={true}
                      isRtl={false}
                      isSearchable={true}
                      name="city"
                      options={cities}
                      placeholder="Search By City"
                    />
                  </div>

                  <div className="input-group-btn">
                    <select className="btn btn-default" name="ext" value={searchCat} onChange={handleChange} style={{ textAlign: 'justify' }}>
                      <option value="0">Sort By Categories</option>
                      {
                        categories && categories.map((category, catIndex) =>
                          <option key={catIndex} value={category.id}>{category.name} ({category.count})</option>
                        )
                      }

                    </select>
                  </div>
                </aside>

                <div
                  id="scrollableDiv"
                  style={{
                    height: 1114,
                    overflow: 'auto',
                    display: 'flex',
                    //flexDirection: 'column-reverse',
                  }}
                >
                  {pageloading?<Loading/>:(shops !== null && shops !== 0 && shops.length > 0) ?
                    <InfiniteScroll
                      dataLength={shops.length}
                      next={fetchmore}
                      hasMore={shops.length != count}
                      endMessage={
                        <p className="alert alert-success mt-4" style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      loader={<Loading />}
                      scrollableTarget="scrollableDiv"
                    >
                      <div className="d-grid grid-col-2">
                        {
                          shops.map((shop, shopIndex) =>
                            <div className="product" key={shopIndex}>
                              <a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}><img src={(shop.image) ? shop.image : default_image} className="img-responsive"
                                alt={shop.name} /></a>
                              <div className="info-bg">
                                <h5><a href={"."} onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}>{shop.name}</a></h5>
                                <p className='mb-0'>{shop.city} - {shop.pincode}</p>
                                <p className='mt-0' style={{ fontSize: "12px" }}>{shop.address.slice(0, 45)}...</p>
                                <ul className="d-flex">
                                  <li><a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}><span className="fa fa-eye"></span> Explore Now</a></li>
                                  <li className="margin-effe"><a href='#.' title="Favorite Shops List"><span onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, shop.id) }} className={(shop.store) ? "fa fa-heart" : 'fa fa-heart-o'}></span></a></li>
                                  <li><a href="#sahre" title="Share"><span className="fa fa-share"></span></a></li>
                                </ul>
                              </div>
                            </div>
                          )
                        }
                      </div>
                    </InfiniteScroll>

                    :
                    <div style={{width:"100%"}}>
                      <center><span><strong>Shops Not Found !!!</strong></span></center>
                    </div>
                  }
                </div>

              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Shops