import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
export default function FranchiseeAuth() {
  const redirect = useNavigate();
  useEffect(()=>{
    if (localStorage.getItem('franchisee-token') === null)
      redirect('/franchisee-login');
  });
  return (null)
}
