import React from 'react'
import { useNavigate} from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
export default function Footer(props) {
	const navigate = useNavigate();
	const moveFraud = () => {
		if (localStorage.getItem('login-token') !== null) {
			navigate('/report-fraud');
		} else {
			toast.error("Please login to report against fraud.", { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
		}
	}

	return (
		<>
			<ToastContainer />
			<footer className="w3l-footer-22">
				<section className="fotter-sub">
					<div className="footer">
						<div className="wrapper">
							<div className="text-txt">
								<div className="right-side">
									<h4>Create Your DLMP21 Shops Ads Today!</h4>
									<p className="para-sep">The Best DLMP21 Shop Ads With MLM Mobile App<a href="#download"> Coming Soon.. </a></p>
									<div className="sub-columns">
										<div className="sub-one-left">
											<h6>About </h6>
											<p>DLMP21 is a venture of Dreams Linkage Marketing Pro 21(I)(OPC) Pvt Ltd. Which was established in the year 2017 with a mission of providing daily needs & shops advertisement, door to door and via online portal to the common people. We strive hard to make a hassle free shopping experience for the people & give commissions using the MLM stage on their purchasing amount on DLMP21.</p>
											<div className="columns-2">
												<ul className="social">
													<li><a href="#."><span className="fa fa-facebook"
														aria-hidden="true"></span></a>
													</li>
													<li><a href="#."><span className="fa fa-linkedin"
														aria-hidden="true"></span></a>
													</li>
													<li><a href="#."><span className="fa fa-twitter"
														aria-hidden="true"></span></a>
													</li>
													<li><a href="#."><span className="fa fa-google-plus"
														aria-hidden="true"></span></a>
													</li>
												</ul>
											</div>
										</div>
										<div className="sub-two-right">
											<h6>Quick links</h6>
											<ul>
												<li><Link to={'/franchisee-login'}> <span className='fa fa-angle-double-right mr-2'> Franchisee Login</span></Link></li>
												<li><Link to={'/'}><span className='fa fa-angle-double-right mr-2'> Home</span></Link></li>
												<li><Link to={'/about'}><span className='fa fa-angle-double-right mr-2'> About</span></Link></li>
												{/* <li><Link to={'/services'}><span className='fa fa-angle-double-right mr-2'> Services</span></Link></li> */}
												<li><Link to={'/contact-us'}><span className='fa fa-angle-double-right mr-2'> Contact</span></Link></li>
											</ul>
										</div>
										<div className="sub-two-right">
											<h6>Help & Support</h6>
											<ul>
												<li><Link to={'/faq'}><span className="fa fa-angle-double-right mr-2"></span>Faq</Link></li>
												<li><Link to={'/terms-of-services'}><span className="fa fa-angle-double-right mr-2"></span>Terms
													of Services</Link></li>
												<li><Link to={'/privacy-policy'}><span
												className="fa fa-angle-double-right mr-2"></span>Privacy & Policy</Link></li>
												<li><a href="#." onClick={(event) => { event.preventDefault(); event.persist(); moveFraud() }} title='Report against shop'><span className="fa fa-angle-double-right mr-2"></span>Report</a></li>
											</ul>
										</div>
										<div className="sub-one-left">
											<h6>Contact </h6>
											<div className="column2">
												<div className="href1"><span className="fa fa-envelope-o" aria-hidden="true"></span><a
													href="mailto:info@dlmp21.com">support@dlmp21.com</a>
												</div>
												<div className="href2"><span className="fa fa-phone" aria-hidden="true"></span><a
													href="tel:8856819762">+91 88568 19762</a>
												</div>
												<div>
													<p className="contact-para"><span className="fa fa-map-marker"
														aria-hidden="true"></span>Corporate Office : Dreams Linkage Marketing... Suprabhat Sankul - Near Mini Punjab Restaurant, C/o Purushottam Super Bazar, Dharampeth Extension, NAGPUR - 10</p>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="below-section">
						<div className="wrapper">
							<div className="copyright-footer">
								<div className="columns text-left">
									<p>@{new Date().getFullYear()} DLMP21. All rights reserved.</p>
								</div>
								<ul className="text-right">
									<li><a href="#."><img src="assets/images/payment1.jpg" alt="" className="img-responsive" /></a>
									</li>
									<li><a href="#."><img src="assets/images/payment2.jpg" alt="" className="img-responsive" /></a>
									</li>
									<li><a href="#."><img src="assets/images/payment3.jpg" alt="" className="img-responsive" /></a>
									</li>
									<li><a href="#."><img src="assets/images/payment4.jpg" alt="" className="img-responsive" /></a>
									</li>
								</ul>
							</div>
						</div>
					</div>
					<button onClick={() => window.topFunction()} id="movetop" title="Go to top">
						<span className="fa fa-arrow-up" aria-hidden="true"></span>
					</button>
				</section>
			</footer>
		</>
	)
}