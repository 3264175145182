import React,{useState,useEffect} from 'react'
import { Link, useNavigate} from 'react-router-dom';
import Validations from '../common/Validations';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import { ToastContainer, toast } from 'react-toastify';

export default function Register() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('otp-token') !== null)
      navigate('/verifications');
    if (localStorage.getItem('login-token') !== null)
		  navigate('/dashboard');
  });

  const [res_errors, setRes_errors] = useState([]);
  const [loading,setLoading ]= useState(false);
  let fields = {firstName:'',lastName:'',middleName:'',password:'',mobile:'',email:''};
  
  const custRegister = async () => {
    setLoading(true);
    await Api.post('/customers/signup',jwt_encode(values,process.env.REACT_APP_SECRET_KEY))
    .then(res => {
      const result = jwt_decode(res.data);
      const status = result.data.status;
      setLoading(false);
      if (status==='failure') {
        // result.data.errors;
        let server_errors = result.data.errors;
        if (server_errors.mobile_exit===true) {
          toast.error(server_errors.mobile, {toastId:"errorTranscation1", position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          //navigate('/login');
        }
        setRes_errors(server_errors);
      } else if(status==='success') {
        localStorage.setItem('otp-token',JSON.stringify(result.data.data));
        navigate('/verifications');
      }
    })
  }

  const {values,errors,handleChange,handleSubmit} = Validations(fields,custRegister);

  return (
    <>
    <ToastContainer/>
    <section className="w3l-forms-23">
      <div className="forms23-block">
        <div className="wrapper">
          <h1>
            <Link to="/home" className="logo-2" style={{ color: "#ffffff", fontWeight: "700" }}>DLMP21</Link>
          </h1>
          <div className="d-grid forms23-grids">
            <div className="form23">
              <h6>Sign Up</h6>
              <form onSubmit={handleSubmit} autoComplete='off'>
                <input type="text" name="firstName" className="input-form" onChange={handleChange} placeholder="First name" autoComplete='off' />
                <small className="text-danger">{(errors.firstName)?errors.firstName:"\u00A0"}</small>
                <small className="text-danger">{(!errors.firstName && res_errors.firstName)?res_errors.firstName:"\u00A0"}</small>

                <input type="text" name="middleName" className="input-form" onChange={handleChange} placeholder="Middle name" autoComplete='off'/>
                <small className="text-danger">{(errors.middleName)?errors.middleName:"\u00A0"}</small>
                <small className="text-danger">{(!errors.middleName && res_errors.middleName)?res_errors.middleName:"\u00A0"}</small>

                <input type="text" name="lastName" className="input-form" onChange={handleChange} placeholder="Last name"autoComplete='off' />
                <small className="text-danger">{(errors.lastName)?errors.lastName:"\u00A0"}</small>
                <small className="text-danger">{(!errors.lastName && res_errors.lastName)?res_errors.lastName:"\u00A0"}</small>

                <input type="text" name="mobile" className="input-form" onChange={handleChange} placeholder="Phone number (10 digit only)" autoComplete='off'/>
                <small className="text-danger">{(errors.mobile)?errors.mobile:"\u00A0"}</small>
                <small className="text-danger">{(!errors.mobile && res_errors.mobile)?res_errors.mobile:"\u00A0"}</small>

                <input type="text" name="email" className="input-form" onChange={handleChange} placeholder="Email" autoComplete='off'/>
                <small className="text-danger">{(errors.email)?errors.email:"\u00A0"}</small>
                <small className="text-danger">{(!errors.email && res_errors.email)?res_errors.email:"\u00A0"}</small>

                <input type="password" name="password" className="input-form" onChange={handleChange} placeholder="Password" autoComplete='off' />
                <small className="text-danger">{(errors.password)?errors.password:"\u00A0"}</small>
                <small className="text-danger">{(!errors.password && res_errors.password)?res_errors.password:"\u00A0"}</small>

                <p>By signing up, you agree to our <Link to="/terms">Terms of Use</Link></p>
                <button type="submit" disabled={loading} className="btn button-eff" style={{ padding: 'unset', height: '40px' }}>
									<span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
								</button>
              </form>
              <p>Already have an account? <Link to="/login">Login</Link></p>
            </div>
            <div className="form23-text">
              <h3>Buy, Sell, Find, Shop Ads, Rent & Exchange in one Click</h3>
              <p>Our jobs as marketers are to understand how the customer wants to buy and help them to do so.</p>
              <Link to="/" className="btn button-eff button-eff-2"><span className="fa fa-hand-o-left"
                aria-hidden="true"></span>
                Back to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
    </>
  )
}
