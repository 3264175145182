import {useEffect } from 'react'
import {useNavigate } from 'react-router-dom';
const CheckVerifySession = () => {
  const navigate = useNavigate();
	useEffect(() => {
    console.log(localStorage);
		if (localStorage.getItem('otp-token') === null)
			navigate('/register');
		if (localStorage.getItem('login-token') !== null)
			navigate('/dashboard');
	});
}

export default CheckVerifySession