import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import FranchiseeAuth from '../common/FranchiseeAuth';
import bsCustomFileInput from 'bs-custom-file-input';

function Profile() {
  const [pageloading, setpageLoading] = useState(false);
  const [loading1, setLoading1] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [loading3, setLoading3] = useState(false);
  const [profile, setProfile] = useState({});
  const [images, setImages] = useState('');
  const [errors, setErrors] = useState({});
  const [labels, setLables] = useState({image1:'choose file',image2:'choose file',image3:'choose file'});

  useEffect(() => {
    bsCustomFileInput.init();
    const customer = JSON.parse(localStorage.getItem('franchisee-token'));
    setpageLoading(true);
    const fetchdata = async () => {
      await Api.post('franchisee/images', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            //console.log(result.data.data);
            setProfile(result.data.data);
          }
        });
    };
    fetchdata();
  }, []);

  const handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.files[0];
    setLables({ ...labels, [name]: value.name, })
    setImages(value)
  }

  const uploadImage1 = (event) => {
    event.preventDefault();
    event.persist();
    setLoading1(true);
    const formData = new FormData();
    formData.append("franchisees[franchisee_image1]",images);
    formData.append('profile',jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
    formData.append('calling','1')
    onFileUpload(formData);
    //setLoading1(false);
  }

  const uploadImage2 = (event) => {
    event.preventDefault();
    event.persist();
    setLoading2(true);
    const formData = new FormData();
    formData.append("franchisees[franchisee_image2]",images);
    formData.append('profile',jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
    formData.append('calling','2')
    onFileUpload(formData);
    //setLoading2(false);
  }

  const uploadImage3 = (event) => {
    event.preventDefault();
    event.persist();
    setLoading3(true);
    const formData = new FormData();
    formData.append("franchisees[franchisee_image3]",images);
    formData.append('profile',jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
    formData.append('calling','3')
    onFileUpload(formData);
    //setLoading3(false);
  }

  const onFileUpload = async (formData) => {
    //submitValidate(profile, errors);
    await Api.post('franchisee/upload', formData)
      .then(res => {
        setLoading1(false);
        setLoading2(false);
        setLoading3(false);
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          setErrors(result.data.errors);
        } else if (status === 'success') {
          setErrors({});
          setProfile(result.data.data);
          toast.success('shop images updated sucessfully !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        }
      });
  }

  return (
    <>
      <FranchiseeAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Shop Images</h3>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <div className="card-body">

                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Main Display Image</label>
                            <div className='input-group'>
                              <div className="custom-file">
                                <input name='image1' onChange={handleChange} id="inputGroupFile01" type="file" className={errors.franchisee_image1?"custom-file-input is-invalid":"custom-file-input"} />
                                <label className="custom-file-label" htmlFor="inputGroupFile01">{labels.image1}</label>
                              </div>
                              <div className="input-group-append">
                                <span className="input-group-text" onClick={uploadImage1} style={{cursor:'pointer'}} id=""> Upload {loading1 && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}</span>
                              </div>
                            </div>
                            {errors.franchisee_image1 && <span id="exampleInputEmail1-error" className="error invalid-feedback" style={{display:'block'}}>{errors.franchisee_image1}</span>}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <img alt='' src={(profile.franchisee_image1)?profile.franchisee_image1:'...'} />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Slider Image</label>
                            <div className='input-group'>
                              <div className="custom-file">
                                <input name='image2' onChange={handleChange} id="inputGroupFile02" type="file" className={errors.franchisee_image2?"custom-file-input is-invalid":"custom-file-input"} />
                                <label className="custom-file-label" htmlFor="inputGroupFile02">{labels.image2}</label>
                              </div>
                              <div className="input-group-append">
                                <span className="input-group-text" onClick={uploadImage2} style={{cursor:'pointer'}} id=""> Upload {loading2 && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}</span>
                              </div>
                            </div>
                            {errors.franchisee_image2 && <span id="exampleInputEmail2-error" className="error invalid-feedback" style={{display:'block'}}>{errors.franchisee_image2}</span>}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <img alt='' src={(profile.franchisee_image2)?profile.franchisee_image2:'...'} />
                          </div>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-md-8">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Slider Image</label>
                            <div className='input-group'>
                              <div className="custom-file">
                                <input name='image3' onChange={handleChange} id="inputGroupFile03" type="file" className={errors.franchisee_image3?"custom-file-input is-invalid":"custom-file-input"} />
                                <label className="custom-file-label" htmlFor="inputGroupFile03">{labels.image3}</label>
                              </div>
                              <div className="input-group-append">
                                <span className="input-group-text" onClick={uploadImage3} style={{cursor:'pointer'}} id=""> Upload {loading3 && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>}</span>
                              </div>
                            </div>
                            {errors.franchisee_image3 && <span id="exampleInputEmail3-error" className="error invalid-feedback" style={{display:'block'}}>{errors.franchisee_image3}</span>}
                          </div>
                        </div>

                        <div className="col-md-4">
                          <div className="form-group">
                            <img alt='' src={(profile.franchisee_image3)?profile.franchisee_image3:'...'} />
                          </div>
                        </div>
                      </div>

                    </div>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}

export default Profile