import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import FranchiseeAuth from '../common/FranchiseeAuth';

function Sales() {
  const [pageloading, setpageLoading] = useState(false);
  const [sales, setSales] = useState(null);
  const [count, setcount] = useState(null);
  const [counter, setCounter] = useState(0)
  const [filter, setFilter] = useState("all");
  useEffect(() => {
    const fetchdata = async () => {
      let customer = JSON.parse(localStorage.getItem('franchisee-token'));
      const data = { customer: customer, filter: "all" }
      setpageLoading(true);
      await Api.post('franchisee/sales', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setSales(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setSales(result.data.data);
            setcount(result.data.count);
          }
        });
    };
    fetchdata();
  }, []);

  const handlePrevious = async () => {
    let number = counter - 1;
    setCounter(number);
    getdata(number,filter);
  }

  const handleNext = async () => {
    let number = counter + 1;
    setCounter(number);
    getdata(number,filter);
  }

  const handleFilter = (event) => {
    let filter = event.target.value;
    setFilter(filter);
    setCounter(0);
    getdata(0, filter);
  }

  const getdata = async (offset = 0, filter = "all") => {
    let customer = JSON.parse(localStorage.getItem('franchisee-token'));
    const data = { customer: customer, filter: filter }
    setpageLoading(true);
    await Api.post('franchisee/sales/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          setpageLoading(false);
          setSales(null);
          toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          setpageLoading(false);
          setSales(result.data.data);
          setcount(result.data.count);
        }
      });
  };

  return (
    <>
      <FranchiseeAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title mb-2">Sales</h3>
                    <div className="btn-group btn-group-toggle" data-toggle="buttons" style={{ float: "right" }}>
                      <label className={(filter === "all") ? "btn btn-secondary active" : "btn btn-secondary"} style={{ fontWeight: "unset", fontSize: "13px" }}>
                        <input type="radio" name="options" onChange={handleFilter} id="option1" value={"all"} autoComplete="off" checked={(filter === "all") ? true : false} />All Time
                      </label>
                      <label className={(filter === "today") ? "btn btn-secondary active" : "btn btn-secondary"} style={{ fontWeight: "unset", fontSize: "13px" }}>
                        <input type="radio" name="options" onChange={handleFilter} id="option2" value={"today"} autoComplete="off" /> Today
                      </label>
                      <label className={(filter === "week") ? "btn btn-secondary active" : "btn btn-secondary"} style={{ fontWeight: "unset", fontSize: "13px" }}>
                        <input type="radio" name="options" onChange={handleFilter} id="option3" value={"week"} autoComplete="off" /> This Week
                      </label>
                      <label className={(filter === "month") ? "btn btn-secondary active" : "btn btn-secondary"} style={{ fontWeight: "unset", fontSize: "13px" }}>
                        <input type="radio" name="options" onChange={handleFilter} id="option4" value={"month"} autoComplete="off" /> This Month
                      </label>
                    </div>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <div className="card-body table-responsive p-0">
                      <table className="table table-hover text-nowrap">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Customer</th>
                            <th>Items</th>
                            <th>Amount</th>
                            <th>Sale Interest</th>
                          </tr>
                        </thead>
                        {(sales !== null) ?
                          <tbody>
                            {
                              sales.map((sale, saleIndex) =>
                                <tr key={saleIndex}>
                                  <td><strong>{sale.created_at}</strong></td>
                                  <td><span className='text-success'>{sale.customer_code}</span></td>
                                  <td><span className=''>{sale.items.slice(0, 15)}...</span></td>
                                  <td><span className="text-success">{sale.amount}</span></td>
                                  <td><span className="text-danger">{sale.sale_interest}</span></td>
                                </tr>
                              )
                            }
                          </tbody>
                          :
                          <tbody>
                            <tr colSpan={4}>
                              <td>Transactions not found !!!</td>
                            </tr>
                          </tbody>

                        }
                      </table>
                    </div>
                  }
                  <div className="card-footer clearfix">
                    <button disabled={(counter <= 0 ? true : false)} onClick={handlePrevious} className="btn btn-sm btn-info float-left">Previous</button>
                    <button disabled={(counter + 1) >= Math.ceil(count / 5)} onClick={handleNext} className="btn btn-sm btn-info float-right">Next</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}

export default Sales