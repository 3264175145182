import React from 'react'

export default function Banner() {
	return (
		<section className="w3l-banner-3-main">
        <div className="banner-3">
            <div className="wrapper">
                <div className="cover-top-center-9">
                    <div className="w3ls_cover_txt-9">
                        <h3 className="title-cover-9">Buy, Sell, Find, Shop Ads, Rent & Exchange in one Click</h3>
                        <p className="para-cover-9">Our jobs as marketers are to understand how the customer wants to buy and help them to do so.</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
	)
}