import { React, useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Validations from '../common/Validations';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";

function Login() {
	const navigate = useNavigate();
	useEffect(() => {
		if (localStorage.getItem('login-token') !== null)
			navigate('/dashboard');
	});
	const [reserrors, setReserrors] = useState({});
	const [loading, setLoading] = useState(false);
	let fields = { password: '', mobile: '' };
	const custLogin = async () => {
		setLoading(true);
		await Api.post('/customers/login', jwt_encode(values, process.env.REACT_APP_SECRET_KEY))
			.then(res => {
				const result = jwt_decode(res.data);
				const status = result.data.status;
				setLoading(false);
				if (status === 'failure') {
					setReserrors(result.data.errors);
				} else if (status === 'success') {
					localStorage.setItem('login-token', JSON.stringify(result.data.data));
					navigate('/dashboard');
				}
			})
	}

	const { values, errors, handleChange, handleSubmit } = Validations(fields, custLogin);
	return (
		<section className="w3l-forms-23">
			<div className="forms23-block">
				<div className="wrapper">
					<h1>
						<Link to="/home" className="logo-2" style={{ color: "#ffffff", fontWeight: "700" }}>DLMP21</Link>
					</h1>
					<div className="d-grid forms23-grids">
						<div className="form23">
							<h6 className='text-center'>Login to your account</h6>
							<form onSubmit={handleSubmit}>
								<input type="text" name="mobile" className="input-form" onChange={handleChange} placeholder="Phone number (10 digit only)" />
								<small className="text-danger">{(errors.mobile) ? errors.mobile : "\u00A0"}</small>
								<small className="text-danger">{(!errors.mobile && reserrors.mobile) ? reserrors.mobile : "\u00A0"}</small>
								<input type="password" name="password" className="input-form" onChange={handleChange} placeholder="Password" />
								<small className="text-danger">{(errors.password) ? errors.password : "\u00A0"}</small>
								<small className="text-danger">{(!errors.password && reserrors.password) ? reserrors.password : "\u00A0"}</small>
								<button type="submit" className="btn button-eff" style={{ padding: 'unset', height: '40px' }}>
									<span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
								</button>
							</form>
							<p className='text-center'>Not a member yet? <Link to="/register">Join Now!</Link></p>
							<p className='text-center'><Link style={{ color: 'blue' }} to="/forgot-password">Forgot your Password ?</Link></p>
						</div>
						<div className="form23-text">
							<h3>Buy, Sell, Find, Shop Ads, Rent & Exchange in one Click</h3>
							<p>Our jobs as marketers are to understand how the customer wants to buy and help them to do so.
							</p>
							<Link to="/" className="btn button-eff button-eff-2"><span className="fa fa-hand-o-left"
								aria-hidden="true"></span>
								Back to Home</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default Login