import React, { useState, useEffect, CSSProperties } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import CustomerAuth from '../common/CustomerAuth';
import { omit } from 'lodash'
import Select from 'react-select';
import Cities from "../cities.json"
//import Cities from '../common/Cities';

function Profile() {
  const cities = Cities;
  const [pageloading, setpageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('login-token'));
    setpageLoading(true);
    const fetchdata = async () => {
      window.$('.select2').select2();
      await Api.post('customers/profile', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            //console.log(result.data.data);
            setProfile(result.data.data);
          }
        });
    };
    fetchdata();
  }, []);

  const handleChange = (event) => {
    //To stop default events    
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    changeValidate(event, name, value);
    //Let's set these values in state
    setProfile({ ...profile, [name]: value, })
  }

  const handleCity = (event) => {
    let city = event.value;
    setProfile({ ...profile, ["city"]: city, })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setProfile({ ...profile, ["city"]: event.target.city.value, })
    //submitValidate(profile, errors);
    setLoading(true);
    await Api.post('customers/update', jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        setLoading(false);
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          setErrors(result.data.errors);
        } else if (status === 'success') {
          localStorage.setItem('login-token', JSON.stringify(result.data.data));
          setErrors({});
          toast.success('your profile updated sucessfully !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        }
      });
  }

  const changeValidate = (event, name, value) => {
    //A function to validate each input values
    switch (name) {
      case 'firstName':
        if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
          setErrors({ ...errors, firstName: 'you can not use numbers,symbols & spaces in first name.' })
        } else {
          let newObj = omit(errors, "firstName");
          setErrors(newObj);
        }
        break;

      case 'middleName':
        if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
          setErrors({ ...errors, middleName: 'you can not use numbers,symbols & spaces in middle name.' })
        } else {
          let newObj = omit(errors, "middleName");
          setErrors(newObj);
        }
        break;

      case 'lastName':
        if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
          setErrors({ ...errors, lastName: 'you can not use numbers,symbols & spaces in last name.' })
        } else {
          let newObj = omit(errors, "lastName");
          setErrors(newObj);
        }
        break;


      case 'age':
        if (!/^[0-9]+$/.test(value)) {
          setErrors({ ...errors, age: 'age should be numbers.' })
        } else if (value.trim().length !== 2) {
          setErrors({ ...errors, age: 'age should be exact 2 numbers.' })
        } else {
          let newObj = omit(errors, "age");
          setErrors(newObj);
        }
        break;

      case 'aadhar_no':
        if (!/^[0-9]+$/.test(value)) {
          setErrors({ ...errors, aadhar_no: 'aadhar no should be numbers.' })
        } else if (value.trim().length !== 12) {
          setErrors({ ...errors, aadhar_no: 'aadhar no should be exact 12 numbers.' })
        } else {
          let newObj = omit(errors, "aadhar_no");
          setErrors(newObj);
        }
        break;

      case 'pan_no':
        if (!/^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/.test(value)) {
          setErrors({ ...errors, pan_no: 'please enter valid pan card number.' })
        } else {
          let newObj = omit(errors, "pan_no");
          setErrors(newObj);
        }
        break;

      case 'pincode':
        if (!/^[0-9]+$/.test(value)) {
          setErrors({ ...errors, pincode: 'pincode should be numbers.' })
        } else if (value.trim().length !== 6) {
          setErrors({ ...errors, pincode: 'pincode should be exact 6 numbers.' })
        } else {
          let newObj = omit(errors, "pincode");
          setErrors(newObj);
        }
        break;

      default:
        break;
    }
  }

  // const submitValidate = (profile, errors) => {
  //   if (profile.firstName.length === 0) {
  //     setErrors({ ...errors, firstName: 'please enter first name.' })
  //   } else {
  //     let newObj = omit(errors, "firstName");
  //     setErrors(newObj);
  //   }

  //   if (profile.middleName.length === 0) {
  //     setErrors({ ...errors, middleName: 'please enter middle name.' })
  //   } else {
  //     let newObj = omit(errors, "middleName");
  //     setErrors(newObj);
  //   }

  //   if (profile.lastName.length === 0) {
  //     setErrors({ ...errors, lastName: 'please enter last name.' })
  //   } else {
  //     let newObj = omit(errors, "lastName");
  //     setErrors(newObj);
  //   }

  //   if (profile.gender.length === 0) {
  //     setErrors({ ...errors, gender: 'please select gender.' })
  //   } else {
  //     let newObj = omit(errors, "gender");
  //     setErrors(newObj);
  //   }

  //   if (profile.age.length === 0) {
  //     setErrors({ ...errors, gender: 'please enter age.' })
  //   } else {
  //     let newObj = omit(errors, "gender");
  //     setErrors(newObj);
  //   }

  //   if (profile.aadhar_no.length === 0) {
  //     console.log("yahape");
  //     setErrors({ ...errors, aadhar_no: 'please enter aadhar no.' })
  //   } else {
  //     let newObj = omit(errors, "aadhar_no");
  //     setErrors(newObj);
  //   }

  //   if (profile.pan_no.length === 0) {
  //     setErrors({ ...errors, pan_no: 'please enter pan no.' })
  //   } else {
  //     let newObj = omit(errors, "pan_no");
  //     setErrors(newObj);
  //   }

  //   if (profile.state.length === 0) {
  //     setErrors({ ...errors, state: 'please enter state.' })
  //   } else {
  //     let newObj = omit(errors, "state");
  //     setErrors(newObj);
  //   }

  //   if (profile.city.length === 0) {
  //     setErrors({ ...errors, city: 'please enter city.' })
  //   } else {
  //     let newObj = omit(errors, "city");
  //     setErrors(newObj);
  //   }

  //   if (profile.pincode.length === 0) {
  //     setErrors({ ...errors, pincode: 'please enter pincode.' })
  //   } else {
  //     let newObj = omit(errors, "pincode");
  //     setErrors(newObj);
  //   }

  //   if (profile.address.length === 0) {
  //     setErrors({ ...errors, address: 'please enter address.' })
  //   } else {
  //     let newObj = omit(errors, "address");
  //     setErrors(newObj);
  //   }
  // }

  return (
    <>
      <CustomerAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">My Profile</h3>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">First Name</label>
                              <input type="text" name="firstName" value={profile.firstName} onChange={handleChange} className={(errors.firstName) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter First Name" style={{ padding: ".375rem .75rem" }} />
                              {errors.firstName && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.firstName}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Middle Name</label>
                              <input type="text" name="middleName" value={profile.middleName} onChange={handleChange} className={(errors.middleName) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Middle Name" style={{ padding: ".375rem .75rem" }} />
                              {errors.middleName && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.middleName}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Last Name</label>
                              <input type="text" name="lastName" value={profile.lastName} onChange={handleChange} className={(errors.lastName) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Last Name" style={{ padding: ".375rem .75rem" }} />
                              {errors.lastName && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.lastName}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email</label>
                              <input type="text" name="email" value={profile.email} onChange={handleChange} className={(errors.email) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Email" style={{ padding: ".375rem .75rem" }} />
                              {errors.email && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.email}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Gender</label>
                              <select name="gender" onChange={handleChange} defaultValue={profile.gender} className={(errors.gender) ? 'form-control is-invalid' : 'form-control'} style={{ padding: ".375rem .75rem" }}>
                                <option value="Male">Male</option>
                                <option value="Female">Female</option>
                              </select>
                              {errors.gender && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.gender}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Age</label>
                              <input type="text" name="age" maxLength={2} value={profile.age} onChange={handleChange} className={(errors.age) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Age" style={{ padding: ".375rem .75rem" }} />
                              {errors.age && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.age}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Aadhar No</label>
                              <input type="text" name="aadhar_no" value={profile.aadhar_no} onChange={handleChange} className={(errors.aadhar_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Aadhar No." style={{ padding: ".375rem .75rem" }} />
                              {errors.aadhar_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.aadhar_no}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Pan No</label>
                              <input type="text" name="pan_no" value={profile.pan_no} onChange={handleChange} className={(errors.pan_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Pan No." style={{ padding: ".375rem .75rem" }} />
                              {errors.pan_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.pan_no}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">State</label>
                              <select name="state" onChange={handleChange} defaultValue={profile.state} className={(errors.state) ? 'form-control is-invalid' : 'form-control'} style={{ padding: ".375rem .75rem" }}>
                                <option value="" >--select state--</option>
                                <option value="MAHARASHTRA">MAHARASHTRA</option>
                              </select>
                              {errors.state && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.state}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group" data-select2-id="29">
                              <label>City</label>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={handleCity}
                                defaultValue={(profile.city) ? { label: profile.city, value: profile.city } : null}
                                isDisabled={false}
                                isLoading={true}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="city"
                                options={cities}
                                placeholder="Select City"
                              />
                              {errors.city && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.city}</span>}
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">City</label>
                              <input type="text" name="city" value={profile.city} onChange={handleChange} className={(errors.city) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter City" />
                              {errors.city && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.city}</span>}
                            </div>
                          </div> */}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Pincode</label>
                              <input type="text" name="pincode" value={profile.pincode} onChange={handleChange} className={(errors.pincode) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Pincode" style={{ padding: ".375rem .75rem" }} />
                              {errors.pincode && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.pincode}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Residence Address</label>
                              <textarea type="text" name="address" value={profile.address} onChange={handleChange} className={(errors.address) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Residence Address" style={{ padding: ".375rem .75rem" }} />
                              {errors.address && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.address}</span>}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <button type="submit" className="btn button-eff">
                          <span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                        </button>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}

export default Profile