import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import Home from './home/Home.js'
import HowItsWorks from './pages/HowItsWorks.js'
import NotFound from './pages/NotFound.js'
import Profile from './customer/Profile';
import Register from './customer/Register';
import Login from './customer/Login';
import Verifications from './customer/Verifications';
import Dashboard from './customer/Dashboard';
import Logout from './customer/Logout';
import Forgot from './customer/Forgot';
import Reset from './customer/Reset';
import ForgotVerify from './customer/ForgotVerify';
import ScrollToTop from './common/ScrollToTop';
import Downline from './customer/Downline';
import Franchisee from './franchisee/Login';
import FranchiseeDashboard from './franchisee/Dashboard';
import FranchiseeProfile from './franchisee/Profile';
import Images from './franchisee/Images';
import Transactions from './franchisee/Transactions';
import AddSales from './franchisee/AddSales';
import Sales from './franchisee/Sales';
import Purchase from './customer/Purchase';
import Commissions from './customer/Commisions';
import Shops from './shops/Shops';
import Kyc from './customer/Kyc';
import Single from './shops/Single';
import News from './news/News';
import Read from './news/Read';
import Comments from './news/Comments';
import Favorites from './customer/Favorites';
import Contacts from './pages/Contacts';
import { Report } from './customer/Report';
import { Faq } from './pages/Faq';
import { About } from './pages/About';
import Terms from './pages/Terms';
import { Privacy } from './pages/Privacy';
function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/how-its-works" element={<HowItsWorks />} />
        <Route path='/profile' element={<Profile/>} />
        <Route path='/kyc' element={<Kyc/>} />
        <Route path='/downline' element={<Downline/>} />
        <Route path='/register' element={<Register />}/>
        <Route path='/login' element={<Login />}/>
        <Route path='/verifications' element={<Verifications/>}/>
        <Route path='/dashboard' element={<Dashboard/>}/>
        <Route path='/logout' element={<Logout/>}/>
        <Route path='/forgot-password' element={<Forgot/>}/>
        <Route path='/forgot-check' element={<ForgotVerify/>}/>
        <Route path='/reset-password' element={<Reset/>}/>
        <Route path='/franchisee-login' element={<Franchisee/>}/>
        <Route path='/franchisee-dashboard' element={<FranchiseeDashboard/>}/>
        <Route path='/franchisee-profile' element={<FranchiseeProfile/>} />
        <Route path='/franchisee-images' element={<Images/>} />
        <Route path='/franchisee-transactions' element={<Transactions/>} />
        <Route path='/franchisee-addsales' element={<AddSales/>} />
        <Route path='/franchisee-sales' element={<Sales/>} />
        <Route path='/purchase' element={<Purchase/>} />
        <Route path='/commisions' element={<Commissions/>} />
        <Route path='/shops' element={<Shops/>} />
        <Route path="/single" element={<Single/>} />
        <Route path="/favorites" element={<Favorites/>} />
        <Route path="/news" element={<News/>} />
        <Route path="/news-read" element={<Read/>} />
        <Route path="/comments" element={<Comments/>} />
        <Route path="/contact-us" element={<Contacts/>} />
        <Route path="/report-fraud" element={<Report/>} />
        <Route path="/faq" element={<Faq/>} />
        <Route path="/about" element={<About/>} />
        <Route path="/terms-of-services" element={<Terms/>} />
        <Route path="/privacy-policy" element={<Privacy/>} />
        <Route path="*" element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
