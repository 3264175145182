import React from 'react'
import { Map, GoogleApiWrapper, Marker, InfoWindow, showInfoWindow } from 'google-maps-react';

const mapStyles = {
  width: '58.5%',
  height: '19%'
};

export const MapContainer = (props) => {
  return (
    <Map
      google={props.google}
      zoom={14}
      style={mapStyles}
      id={1}
      draggable={true}
    >
      <Marker
        title={'The marker`s title will appear as a tooltip.'}
        name={'SOMA'}
        position={{ lat: 37.778519, lng: -122.405640 }}>
        <InfoWindow
          visible={showInfoWindow}
        >
          <div>
            <p>Click on the map or drag the marker to select location where the incident occurred</p>
          </div>
        </InfoWindow>
      </Marker>
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: 'AIzaSyDj6bLDLDuaqOFGju6QujSUJa47Hzzwvgc'
})(MapContainer);