import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
export default function CustomerAuth() {
  const redirect = useNavigate();
  useEffect(()=>{
    if (localStorage.getItem('login-token') === null)
      redirect('/login');
  });
  return (null)
}
