import React from 'react'
import Header from '../common/Header.js'
import InnerBanner from '../common/InnerBanner.js'
import Footer from '../common/Footer.js'
export const Privacy = () => {
  return (<>
    <Header />
    <InnerBanner title={'SECURITY & PRIVACY POLICY'} />
    <div className="w3l-faq-main">
      <div className="faq-sec sec-padding">
        <div className="wrapper">
          <div className="faq-cont">
            <div className="about-right-faq">
              <div className="bg-warning text-dark"><b>Warning :</b> Please, Read Carefully our Security & Privacy Policy.</div>
              <br />
              <h4 className="head-terms"><b>PRIVACY AND INFORMATION SECURITY POLICY</b></h4>
              <p className="all-terms">Dreams Linkage Marketing Pro 21 (I)(OPC) PVT.LTD.is the
                licensed owner of Dlmp21.com. DLMP21 is committed
                to safeguarding your privacy with respect to the personally
                identifiable information that we may obtain from you in
                conjunction with your use of the DLMP21 service. Our
                Privacy and Information Security Policy (&quot;Privacy Policy&quot;)
                answers frequently asked questions about your online privacy,
                including what personally identifiable information we may
                obtain from you and how it will be used and protected.</p>
              <br />
              <h4 className="head-terms"><b>INFORMATION COLLECTION AND USE</b></h4>
              <p className="all-terms">DLMP21 may at times collect personally identifiable
                information. On such occasions, DLMP21 may ask for
                your name, email address, zip code and personal information
                concerning your interests, occupation, gender and other
                demographic information. DLMP21 also receives and records
                information from server logs from your browser including
                cookie information relating to pages requested and the like.
                DLMP21 does not seek to collect such information from persons
                under the age of 18. If you are under the age of 18, please do not
                submit any such information to us. If you are the parent or
                guardian of a person under the age of 18 who has provided such
                information to us, please inform us and we will remove such
                information from our database as described further below. In
                addition, no person (of any age) is required to provide us with

                the personally identifiable information we request. If you do not
                wish to provide such information, you are free not to do so.
                Your provision of requested information to us through our
                Website or other means is presumed by us to be voluntary and
                with your consent to the uses described herein. If after
                submitting personally identifiable information to us, you wish to
                &quot;opt-out,&quot; please contact us and we will remove your
                information from our database as set forth below in the section
                titled &quot;Managing Your Information.&quot; Please know that such a
                decision will not stop advertising or content that is generated
                prior to the time when we can accomplish the removal of your
                information. DLMP21 uses the information you provide to us to
                customize content that we present to you, to contact you
                concerning specials and new products, to offer news and
                newsletters, and to enhance our product offering. DLMP21 will
                not sell or rent your personally identifiable information to any
                third party except when: -You have been given an opportunity to
                &quot;opt out&quot; of sharing the information and have declined to
                exercise that right within the time period provided; - DLMP21
                must share the information in order to provide a product or
                service that you have requested, in which case we will demand
                that any third party we use to fulfill your order agree that it does
                not have the right to use the personally identifiable information
                other than to fulfill your request; or - DLMP21 is required to
                respond to subpoena, court orders or other legal process
                requiring such disclosure.</p>
              <br />
              <h4 className="head-terms"><b>USE OF COOKIES</b></h4>
              <p className="all-terms">Cookies are pieces of information that a website transfers to any individual’s hard drive for record-keeping purposes. Cookies
                make Web-surfing easier for you by saving your passwords,
                purchases, and preferences while you’re at our site. The use of
                cookies is an industry standard—you’ll find them at most major
                websites. By showing how and when guests use the site, cookies
                help us see which areas are popular and which are not. Many
                improvements and updates to the site are based on such data as
                total number of visitors and pages viewed. This information is
                most easily tracked with cookies. If you accept the first tracking
                cookie from Dlmp21.com, you won’t be offered as many
                afterwards. You may occasionally get cookies from our
                advertisers. Dlmp21.com does not control these cookies, and
                these cookies are not subject to the Dlmp21.com cookies policy.
                The use of advertising cookies sent by third-party servers is
                standard in the Internet industry. Most browsers are initially set
                to accept cookies. If you’d prefer, you can set yours to refuse
                cookies or to alert you when cookies are being sent. However, it
                is possible that some parts of the site, like the shopping areas,
                will not function properly if you do so.</p>
              <br />
              <h4 className="head-terms"><b>MANAGING YOUR INFORMATION</b></h4>
              <p className="all-terms">You are entitled to request access to and correction of all your personal data collected by us via this Website, as well as to
                require us to delete your data if you no longer want us to process
                it. You can make such a request or a related inquiry by emailing
                us at info@dlmp21.com. Please note that there may be a small
                charge for making an access request or it may be free, depending
                on the circumstances. You can find out more details by emailing
                us as provided above. We will keep your data as long as it is
                reasonably necessary for the purposes described in this Privacy
                Policy and Rules. You may choose not to receive unsolicited
                marketing material from us and you have the right to request
                deletion of your DLMP2 MART account information, subject to
                DLMP21 need to keep appropriate archives of its business
                operations.</p>

              <h4 className="head-terms"><b>ABOUT IP ADDRESS</b></h4>
              <p className="all-terms">DLMP21 reserves the right to collect your IP address(s) for the purposes of system administration and security reasons. An IP
                address is a number that’s automatically assigned to your
                computer whenever you’re surfing the Web. Web servers—the
                big computers that “serve” up Web pages—automatically
                identify your computer by its IP address. When guests request
                pages from DLMP21, our servers log their IP addresses.
                We do not link IP addresses to anything personally identifiable.
                This means that a user’s session will be logged, but the user
                remains anonymous to us.</p>
              <br />
              <h4 className="head-terms"><b>SECURITY</b></h4>
              <p className="all-terms">To protect the security and quality of your personal information, we have implemented technical and managerial procedures to
                maintain accurate, current and complete information as well as
                to protect your personal information against loss, misuse or
                alteration when it is under our control. Your personally
                identifiable information will be encrypted and stored on our
                secured servers. Your personal information is also password
                protected so that access is limited to yourself and those with
                whom you share your password, Company, as well as third party
                access facilitated by Company with your prior permission. We
                use reasonable measures to limit the disclosure of the data you
                provide to us only to certain staff within DLMP21 and its
                affiliates (i.e. staff whose job it is to process your inquiries and
                survey answers or otherwise to administer the Website, and
                those staff involved in the provision of DLMP21 services and
                products which may be of interest to you) and to require that
                they process the data only for the purposes identified in this
                Privacy Policy and Rules.</p>
              <br />
              <h4 className="head-terms"><b>PRIVACY POLICIES OF THIRD PARTIES</b></h4>
              <p className="all-terms">Third parties that have links on our web site may collect
                personally identifiable information about you. We are not
                responsible for the privacy policies or practices of such sites and
                the practices of these sites are not governed by this Privacy
                Policy. If you have questions about the privacy policies or
                practices of a third party site, you should contact the site
                administrator or web-master of the specific site. We may from
                time to time partner with other companies to offer co-branded
                services as well as sweepstakes, contests and promotions. Any
                information that you provide in connection with the co-branded
                services or any jointly sponsored sweepstakes, contests or
                promotions will become the joint property of Company and its
                business partners. We will maintain your information in
                accordance with the terms of this Privacy Policy. However, this
                Privacy Policy does not govern the privacy policies and
                practices of our business partners. If you have questions about
                the privacy policies or practices of our business partners, you
                should contact them directly.</p>
              <br />
              <h4 className="head-terms"><b>ACCEPTANCE</b></h4>
              <p className="all-terms">There will be no contract of any kind between you and us unless and until we actually dispatch the goods to you. Your order is an offer to buy from us. Nothing that we do or say will amount to any acceptance of that offer until we actually dispatch the goods to you. At any point up until then, we may decline to supply the goods to you without giving any reason. At the moment that the goods are dispatched a contract will be made between you and us, and you will be charged for the goods. This website/app is a medium of procurement of order only. Placing an order on the website/app does not bind in any contract unless and until it is accepted by us and initiation of delivery. To ensure availability of all our products customers may be limited to a maximum number of items. Management Reserves the right to accept the order or to reject it. R&P Retail Concepts Reserves the right to honour any discounts, offers, coupons and order overriding all the other terms and conditions.</p>
              <br />
              <h4 className="head-terms"><b>POLICY UPDATES</b></h4>
              <p className="all-terms">DLMP21 reserves the right to change or update this policy at any time. Such changes shall be effective immediately upon posting to the Site.</p>
              {/* <br />
              <h4 className="head-terms"><b>RETURN POLICY</b></h4>
              <p className="all-terms">We hope you will be happy with your purchase. However, should you feel it necessary to return an item, we aim to make the returns process as simple as possible. We have a ‘NO QUESTIONS ASKED POLICY’.<br />You can return the item on the time of delivery if,<br />a. Item is defected <br />b. Item is expired <br />c. Item is that you are not ordered <br />We will not charge you for any incorrect goods or defective goods which you have received.</p>
              <br />
              <h4 className="head-terms"><b>PAYMENT POLICY</b></h4>
              <p className="all-terms">Right now our process is working on cash on delivery theme.You should pay the correct amount as in the invoice. we accept only Indian rupees.</p>
              <br />
              <h4 className="head-terms"><b>CANCELLATION POLICY</b></h4>
              <p className="all-terms">You can cancel the whole order within two hours of after order placed, if you want to cancel the whole order after two hours
                from order placed, you need to call on company contact number
                within the company business hour of till 7 pm on the same day
                of order placed. If you cancelled the order or no one is available
                to receive the order at the time of delivery you should pay the
                cancellation charges of One Hundred Rupees or it will be
                deducted from your commission as well.</p> */}
              <br />
              <h4 className="head-terms"><b>CONTACT US</b></h4>
              <p className="all-terms">If you have any questions or comments about this Privacy Statement or the practices of our Web Site, please feel free to e-mail us at support@dlmp21.com or visit our contact page.</p>

              <br />
              <h4 className="head-terms"><b>Customer Services</b></h4>
              <p className="all-terms">• Customer Care: 9545501071</p>
              <p className="all-terms">• Email: support@dlmp21.com</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Footer />
  </>)
}
