import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import FranchiseeAuth from '../common/FranchiseeAuth';
import Footer from '../common/Footer';
import Header from '../common/Header';
const Dashboard = () => {
  const [pageloading, setpageLoading] = useState(false);
  const [goal, setGoal] = useState({});
  const [counts, setCounts] = useState({});
  const [transactions, setTransactions] = useState(null);
  const [sales, setSales] = useState(null);
  const navigate = useNavigate();
  useEffect(() => {
    const franchisee = JSON.parse(localStorage.getItem('franchisee-token'));
    const fetchdata = async () => {
      setpageLoading(true);
      await Api.post('franchisee/dashboard', jwt_encode(franchisee, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            let dashboard = result.data.data;
            console.log(dashboard);
            setpageLoading(false);
            setGoal(dashboard.goal);
            setCounts(dashboard.counts);
            setTransactions(dashboard.transactions);
            setSales(dashboard.sales);
            if (dashboard.goal.completed < 5)
              toast.error(dashboard.goal.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          }
        });
    };
    fetchdata();
  }, []);

  return (
    <>
      <FranchiseeAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-blog-single">
        <div className="single blog">
          <div className="wrapper">
            {(pageloading) ? <Loading /> :
              <>
                {goal.status === "failure" &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <p className="text-center">
                                <strong>Profile Completion</strong>
                              </p>

                              <div className="progress-group">
                                Profile Status
                                <span className="float-right"><b>{goal.completed}</b>/ 5</span>
                                <div className="progress progress-sm">
                                  <div className="progress-bar bg-primary" style={{ width: "80%" }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }

                <div className="row">
                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-info">
                      <div className="inner">
                        <h3><i className="fa fa-inr" aria-hidden="true"></i> {counts.wallet}</h3>
                        <p style={{color:"unset"}}>Wallet Balance</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-bag"></i>
                      </div>
                      {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                      <div className="inner">
                        <h3><Link to="/franchisee-addsales"><i className="fa fa-plus" aria-hidden="true"></i> Sale</Link></h3>
                        <p style={{color:"unset"}}><Link to="/franchisee-addsales">Add sale for customers</Link></p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-stats-bars"></i>
                      </div>
                      {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-secondary">
                      <div className="inner">
                        <h3><i className="fa fa-inr" aria-hidden="true"></i> {(counts.sales)?counts.sales:"0.00"}</h3>
                        <p style={{color:"unset"}}>Total Sale</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add"></i>
                      </div>
                      {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                    </div>
                  </div>

                  <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                      <div className="inner">
                        <h3><i className="fa fa-user" aria-hidden="true"></i> {counts.visitors}</h3>
                        <p style={{color:"unset"}}>Unique Customers</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-pie-graph"></i>
                      </div>
                      {/* <a href="#" className="small-box-footer">More info <i className="fas fa-arrow-circle-right"></i></a> */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Latest Sales</h3>

                        <div className="card-tools">
                          <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Customer</th>
                                <th>Items</th>
                                <th>Amount</th>
                                <th>Sale Interest</th>
                              </tr>
                            </thead>
                            {(sales !== null && sales.length !== 0) ?
                              <tbody>
                                {
                                  sales.map((sale, saleIndex) =>
                                    <tr key={saleIndex}>
                                      <td><strong>{sale.created_at}</strong></td>
                                      <td><span className='text-success'>{sale.customer_code}</span></td>
                                      <td><span className=''>{sale.items.slice(0, 15)}...</span></td>
                                      <td><span className="text-success">{sale.amount}</span></td>
                                      <td><span className="text-danger">{sale.sale_interest}</span></td>
                                    </tr>
                                  )
                                }
                              </tbody>
                              :
                              <tbody>
                                <tr>
                                  <td colSpan={4}>Sales not found !!!</td>
                                </tr>
                              </tbody>
                            }
                          </table>
                        </div>
                      </div>
                      <div className="card-footer clearfix">
                        <Link to="/franchisee-sales" className="btn btn-sm btn-secondary float-right">View All Sales</Link>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title"><strong></strong>Latest Transactions</h3>

                        <div className="card-tools">
                          <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                        
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Credited Amount</th>
                                <th>Debited Amount</th>
                                <th>Method</th>
                              </tr>
                            </thead>
                            {(transactions !== null && transactions.length !== 0) ?
                              <tbody>
                                {transactions.map((transact, transIndex) =>
                                  <tr key={transIndex}>
                                    <td><strong>{transact.created_at}</strong></td>
                                    <td><span className='text-success'>{transact.credit}</span></td>
                                    <td><span className='text-danger'>{transact.debit}</span></td>
                                    <td><span className="badge badge-success">{transact.method}</span></td>
                                  </tr>
                                )
                                }
                              </tbody>
                              :
                              <tbody>
                                <tr>
                                  <td colSpan={3}>Transactions not found !!!</td>
                                </tr>
                              </tbody>
                            }
                          </table>
                        </div>
                      </div>
                      <div className="card-footer clearfix">
                        <Link to="/franchisee-transactions" className="btn btn-sm btn-secondary float-right">View All Transactions</Link>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Dashboard