import React from 'react'
import { Link } from 'react-router-dom';
export default function NotFound() {
	return (
    <div className="w3l-error-main">
        <div className="error">
            <div className="wrapper">
                <h1>
                  <Link to="/" className="logo" style={{color:"#ec0009",fontWeight:"700"}}>DLMP21</Link>
                </h1>
                <div className="sub-wthreess">
                    <h3 className="text-err-wthree">Oops!</h3>
                    <h4>404 - Page not found</h4>
                    <p>This page you are looking for might have been removed<br/> had its name changed or temporarly
                        unavailable.
                    </p>
                    <Link to="/" className="btn button-eff">Go to Home</Link>
                </div>
            </div>
        </div>
    </div>		
	)
}