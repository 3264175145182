import { React, useEffect, useState } from 'react'
import Loading from '../common/Loading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import CustomerAuth from '../common/CustomerAuth';
import Footer from '../common/Footer';
import Header from '../common/Header';

function Downline() {
	const [pageloading, setpageLoading] = useState(false);
	const [page, setPage] = useState([]);
	const maleImg = 'assets/tree/images/user8.jpg';
	const femaleImg = 'assets/tree/images/user6.jpg';
	useEffect(() => {
		const customer = JSON.parse(localStorage.getItem('login-token'));
		const fetchdata = async () => {
			setpageLoading(true);
			await Api.post('customers/downline', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
				.then(res => {
					const result = jwt_decode(res.data);
					const status = result.data.status;
					if (status === 'failure') {
						let data_error = result.data.errors;
						toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					} else if (status === 'success') {
						let data = result.data.data;
						setPage([data]);
						setpageLoading(false);
					}
				});
		};
		fetchdata();
		// Tree plugin call
		//window.$('.tree').tree_structure();
		setTimeout(() => {
			window.$('.tree').tree_structure();
			window.$('.overflow').css('visibility', 'visible');;
		}, 1000);

	}, []);
	return (
		<>
		  <CustomerAuth />
			<Header />
			<ToastContainer />
			{(pageloading) ? <Loading /> :
				// <div dangerouslySetInnerHTML={{__html: page.downline}} />
				<div className="overflow" style={{ visibility: 'hidden' }}>
					<div>
						<ul className="tree" rel="1">
							{page.map((value, index) => (
								<li key={index}>
									<span className="vertical" style={{ marginLeft: '55px', left: '619.5px' }}></span><span className="horizontal" style={{ width: '0px', marginLeft: '55px', left: '619.5px' }}></span>
									<div className=""><span className="highlight" title="Highlight" style={{ display: 'none' }}></span><b className="thide"></b>
										<img className="img" alt={value.f_name} src={(value.gender === 'Female' ? femaleImg : maleImg)} />
										<span className="title">{value.f_name + " " + value.l_name}</span>
										<span className="description">{value.city}</span>
									</div>
									<ul>
										{!value.left_side[0].length &&
											<li key={value.left_side[0].id}>
												<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
												<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
													<img className="img" alt={value.left_side[0].f_name} src={(value.left_side[0].gender === 'Female' ? femaleImg : maleImg)} />
													<span className="title">{value.left_side[0].f_name + " " + value.left_side[0].l_name}</span>
													<span className="description">{value.left_side[0].city}</span>
												</div>
												{value.left_side[0].left_side !== "0" && 
													<ul>
														{console.log(value.left_side[0].left_side)}
														{value.left_side[0].left_side.map((ch1, ind1) => (
															<li key={ind1}>
																<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																	<img className="img" alt={ch1.f_name} src={(ch1.gender === 'Female' ? femaleImg : maleImg)} />
																	<span className="title">{ch1.f_name + " " + ch1.l_name}</span>
																	<span className="description">{ch1.city}</span>
																</div>
																<ul>
																	{!ch1.left_side[0].length &&
																		<li key={ch1.left_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch1.left_side[0].f_name} src={(ch1.left_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch1.left_side[0].f_name + " " + ch1.left_side[0].l_name}</span>
																				<span className="description">{ch1.left_side[0].city}</span>
																			</div>
																		</li>
																	}

																	{!ch1.right_side[0].length &&
																		<li key={ch1.right_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch1.right_side[0].f_name} src={(ch1.right_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch1.right_side[0].f_name + " " + ch1.right_side[0].l_name}</span>
																				<span className="description">{ch1.right_side[0].city}</span>
																			</div>
																		</li>
																	}
																</ul>
															</li>		
														))}

														{value.left_side[0].right_side.length !== 0 && value.left_side[0].right_side.map((ch2, ind2) => (
															<li key={ind2}>
																<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																	<img className="img" alt={ch2.f_name} src={(ch2.gender === 'Female' ? femaleImg : maleImg)} />
																	<span className="title">{ch2.f_name + " " + ch2.l_name}</span>
																	<span className="description">{ch2.city}</span>
																</div>
																<ul>
																	{!ch2.left_side[0].length &&
																		<li key={ch2.left_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch2.left_side[0].f_name} src={(ch2.left_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch2.left_side[0].f_name + " " + ch2.left_side[0].l_name}</span>
																				<span className="description">{ch2.left_side[0].city}</span>
																			</div>
																		</li>
																	}

																	{!ch2.right_side[0].length &&
																		<li key={ch2.right_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch2.right_side[0].f_name} src={(ch2.right_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch2.right_side[0].f_name + " " + ch2.right_side[0].l_name}</span>
																				<span className="description">{ch2.right_side[0].city}</span>
																			</div>

																		</li>
																	}
																</ul>
															</li>
														))}
													</ul>
												}
											</li>
										}

										{!value.right_side[0].length &&
											<li key={value.right_side[0].id}>
												<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
												<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
													<img className="img" alt={value.right_side[0].f_name} src={(value.right_side[0].gender === 'Female' ? femaleImg : maleImg)} />
													<span className="title">{value.right_side[0].f_name + " " + value.right_side[0].l_name}</span>
													<span className="description">{value.right_side[0].city}</span>
												</div>
												{value.right_side[0].left_side !== "0" &&
													<ul>
														{value.right_side[0].left_side.map((ch3, ind3) => (
															<li key={ind3}>
																<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																	<img className="img" alt={ch3.f_name} src={(ch3.gender === 'Female' ? femaleImg : maleImg)} />
																	<span className="title">{ch3.f_name + " " + ch3.l_name}</span>
																	<span className="description">{ch3.city}</span>
																</div>
																<ul>
																	{!ch3.left_side[0].length &&
																		<li key={ch3.left_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch3.left_side[0].f_name} src={(ch3.left_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch3.left_side[0].f_name + " " + ch3.left_side[0].l_name}</span>
																				<span className="description">{ch3.left_side[0].city}</span>
																			</div>
																		</li>
																	}

																	{!ch3.right_side[0].length &&
																		<li key={ch3.right_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch3.right_side[0].f_name} src={(ch3.right_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch3.right_side[0].f_name + " " + ch3.right_side[0].l_name}</span>
																				<span className="description">{ch3.right_side[0].city}</span>
																			</div>
																		</li>
																	}
																</ul>
															</li>		
														))}

														{value.right_side[0].right_side.map((ch4, ind4) => (
															<li key={ind4}>
																<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																	<img className="img" alt={ch4.f_name} src={(ch4.gender === 'Female' ? femaleImg : maleImg)} />
																	<span className="title">{ch4.f_name + " " + ch4.l_name}</span>
																	<span className="description">{ch4.city}</span>
																</div>
																<ul>
																	{!ch4.left_side[0].length &&
																		<li key={ch4.left_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch4.left_side[0].f_name} src={(ch4.left_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch4.left_side[0].f_name + " " + ch4.left_side[0].l_name}</span>
																				<span className="description">{ch4.left_side[0].city}</span>
																			</div>
																		</li>
																	}

																	{!ch4.right_side[0].length &&
																		<li key={ch4.right_side[0].id}>
																			<span className="vertical" style={{ height: '86.5px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span><span className="horizontal" style={{ width: '286.688px', marginTop: '-86.5px', marginLeft: '55px', left: '332.812px' }}></span>
																			<div className=""><span className="highlight" title="Highlight"></span><b className="thide"></b>
																				<img className="img" alt={ch4.right_side[0].f_name} src={(ch4.right_side[0].gender === 'Female' ? femaleImg : maleImg)} />
																				<span className="title">{ch4.right_side[0].f_name + " " + ch4.right_side[0].l_name}</span>
																				<span className="description">{ch4.right_side[0].city}</span>
																			</div>
																		</li>
																	}
																</ul>
															</li>
														))}
													</ul>
												}
											</li>
										}
									</ul>
								</li>
							)
							)}
						</ul>
					</div>
				</div>
			}
			<Footer />
		</>
	)
}

export default Downline