import React from 'react'
import { useNavigate } from 'react-router-dom';
import Loading from '../common/Loading';
import default_image from '../default-image.png';
import { ToastContainer, toast } from 'react-toastify';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
export default function Latest(props) {
	const navigate = useNavigate();
	let { latest, featured, bestDeal, loading } = props;
	const moveSingle = (shop_id) => {
		navigate('/single', { state: { id: shop_id } });
	}

	const addFavorite = async (event, shop_id) => {
		if (localStorage.getItem('login-token') !== null) {
			const customer = JSON.parse(localStorage.getItem('login-token'));
			let data = { customer_id: customer.id, franchisee_id: shop_id };
			await Api.post('customers/favorites', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
				.then(res => {
					const result = jwt_decode(res.data);
					const status = result.data.status;
					if (status === 'failure') {
						toast.error(result.data.error, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					} else if (status === 'success') {
						if (result.data.action === "store")
							event.target.className = "fa fa-heart";
						if (result.data.action === "remove")
							event.target.className = "fa fa-heart-o";
						toast.success(result.data.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					}
				});
		} else {
			toast.error('Please login to add favorite !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
		}
	}

	return (
		<>
			<ToastContainer />
			<div className="w3l-products-4">
				<div id="products4-block" className="text-center">
					<div className="wrapper">
						<input id="tab1" type="radio" name="tabs" defaultChecked />
						<label className="tabtle" htmlFor="tab1">Latest Ads</label>

						<input id="tab2" type="radio" name="tabs" />
						<label className="tabtle" htmlFor="tab2">Featured Ads</label>

						<input id="tab3" type="radio" name="tabs" />
						<label className="tabtle" htmlFor="tab3">Best Deal Ads</label>

						<section id="content1" className="tab-content text-left">
							{loading && <Loading />}
							<div className="d-grid grid-col-3">
								{
									!loading && latest.map((latest) => {
										return (
											<div className="product" key={latest.id + "latest"}>
												<a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(latest.id) }}><img src={(latest.image) ? latest.image : default_image} className="img-responsive"
													alt={latest.name} /></a>
												<div className="info-bg">
													<h5><a href={'/shops/' + latest.id}>{latest.name}</a></h5>
													<p className='mb-0'>{latest.city} - {latest.pincode}</p>
                          {latest.address && <p className='mt-0' style={{ fontSize: "12px" }}>{latest.address.slice(0, 45)}...</p>}
													<ul className="d-flex">
														<li><a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(latest.id) }}><span className="fa fa-eye"></span> Explore Now</a></li>
														<li className="margin-effe"><a href='#.' title="Favorite Shops List"><span onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, latest.id) }} className={(latest.store) ? "fa fa-heart" : 'fa fa-heart-o'}></span></a></li>
														<li><a href="#sahre" title="Share"><span className="fa fa-share"></span></a></li>
													</ul>
												</div>
											</div>
										)
									})
								}
							</div>
						</section>

						<section id="content2" className="tab-content text-left">
							{loading && <Loading />}
							<div className="d-grid grid-col-3">
								{
									!loading && featured.map((featured) => {
										return (
											<div className="product" key={featured.id + "featured"}>
												<a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(featured.id) }}><img src={(featured.image) ? featured.image : default_image} className="img-responsive"
													alt={featured.name} /></a>
												<div className="info-bg">
													<h5><a href={'/shops/' + featured.id}>{featured.name}</a></h5>
													<p className='mb-0'>{featured.city} - {featured.pincode}</p>
                          {featured.address && <p className='mt-0' style={{ fontSize: "12px" }}>{featured.address.slice(0, 45)}...</p>}
													<ul className="d-flex">
														<li><a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(featured.id) }}><span className="fa fa-eye"></span> Explore Now</a></li>
														<li className="margin-effe"><a href='#.' title="Favorite Shops List"><span onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, featured.id) }} className={(featured.store) ? "fa fa-heart" : 'fa fa-heart-o'}></span></a></li>
														<li><a href="#sahre" title="Share"><span className="fa fa-share"></span></a></li>
													</ul>
												</div>
											</div>
										)
									})
								}
							</div>
						</section>

						<section id="content3" className="tab-content text-left">
							{loading && <Loading />}
							<div className="d-grid grid-col-3">
								{
									!loading && bestDeal.map((bestDeal) => {
										return (
											<div className="product" key={bestDeal.id + "best"}>
												<a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(bestDeal.id) }}><img src={(bestDeal.image) ? bestDeal.image : default_image} className="img-responsive"
													alt={bestDeal.name} /></a>
												<div className="info-bg">
													<h5><a href={'/shops/' + bestDeal.id}>{bestDeal.name}</a></h5>
													<p className='mb-0'>{bestDeal.city} - {bestDeal.pincode}</p>
                          {bestDeal.address && <p className='mt-0' style={{ fontSize: "12px" }}>{bestDeal.address.slice(0, 45)}...</p>}
													<ul className="d-flex">
														<li><a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(bestDeal.id) }}><span className="fa fa-eye"></span> Explore Now</a></li>
														<li className="margin-effe"><a href='#.' title="Favorite Shops List"><span onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, bestDeal.id) }} className={(bestDeal.store) ? "fa fa-heart" : 'fa fa-heart-o'}></span></a></li>
														<li><a href="#sahre" title="Share"><span className="fa fa-share"></span></a></li>
													</ul>
												</div>
											</div>
										)
									})
								}
							</div>
						</section>
					</div>
				</div>
			</div>
		</>
	)
}
