import {useEffect} from 'react'
import {useNavigate} from 'react-router-dom';
export default function Logout() {
  const navigate = useNavigate();
	useEffect(()=>{
		if (localStorage.getItem('login-token') !== null || localStorage.getItem('franchisee-token') !== null) {
      localStorage.clear();
		  navigate('/');
    }
	});
  return (null)
}
