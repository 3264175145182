import {React,useState,useEffect} from 'react'
import { Link, useNavigate} from 'react-router-dom';
import Validations from '../common/Validations';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";

export default function Reset() {
  const navigate = useNavigate();
  useEffect(() => {
    if (localStorage.getItem('login-token') !== null)
      navigate('/dashboard');
    if (localStorage.getItem('reset-token') === null)
      navigate('/forgot-password');
  });
  const value = JSON.parse(localStorage.getItem('reset-token'));
  const [reserrors, setReserrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [reset,setReset] = useState();
  const [redirect,setRedirect] = useState();
  let fields = { password: '', confirm_password: '' };
  const custReset = async () => {
    setLoading(true);
    let send = {mobile:value.mobile,password:values.password,confirm_password:values.confirm_password};
    await Api.post('/forgot/reset', jwt_encode(send, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        setLoading(false);
        if (status === 'failure') {
          setReserrors(result.data.errors);
        } else if (status === 'success') {
          setReset(result.data.data.message);
          setRedirect('Redirect to login page in 10 sec.');
          setTimeout(() => {
            localStorage.removeItem('reset-token');
            navigate('/login');
          }, 10000);
        }
      })
  }

  const { values, errors, handleChange, handleSubmit } = Validations(fields, custReset);
  return (
    <section className="w3l-forms-23">
      <div className="forms23-block">
        <div className="wrapper">
          <h1>
            <Link to="/home" className="logo-2" style={{ color: "#ffffff", fontWeight: "700" }}>DLMP21</Link>
          </h1>
          <div className="d-grid forms23-grids">
            <div className="form23">
              <h6 className='text-center'>Reset Password</h6>
              {reset && <p className="text-center" style={{color:'green'}}>{reset}</p>}
              {redirect && redirect !== 10 && <p className="text-center" style={{color:'green'}}>{redirect}</p>}
              <form onSubmit={handleSubmit}>
                <input type="password" name="password" className="input-form" disabled={reset} onChange={handleChange} placeholder="Password" />
                <small className="text-danger">{(errors.password) ? errors.password : "\u00A0"}</small>
                <small className="text-danger">{(!errors.password && reserrors.password) ? reserrors.password : "\u00A0"}</small>

                <input type="password" name="confirm_password" className="input-form" disabled={reset} onChange={handleChange} placeholder="Confirm Password" />
                <small className="text-danger">{(errors.confirm_password) ? errors.confirm_password : "\u00A0"}</small>
                <small className="text-danger">{(!errors.confirm_password && reserrors.confirm_password) ? reserrors.confirm_password : "\u00A0"}</small>
                
                <button type="submit" className="btn button-eff" style={{ padding: 'unset', height: '40px' }}>
									<span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
								</button>

              </form>
            </div>
            <div className="form23-text">
              <h3>Buy, Sell, Find, Shop Ads, Rent & Exchange in one Click</h3>
              <p>Our jobs as marketers are to understand how the customer wants to buy and help them to do so.
              </p>
              <Link to="/" className="btn button-eff button-eff-2"><span className="fa fa-hand-o-left"
                aria-hidden="true"></span>
                Back to Home</Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}
