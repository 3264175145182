import React from 'react'
import { useNavigate,Link } from 'react-router-dom';
export default function Locations() {
  const customer = JSON.parse(localStorage.getItem('login-token'));
  const navigate = useNavigate();
  const movetoShops = (location) => {
    const search = { search: '', category: 0, city: location, customer: (customer !== null) ? customer.id : 0 };
    navigate('/shops', { state: search });
  }
  return (
    <section className="w3l-grids-9-main">
      <div className="grid-top-9">
        <div className="wrapper">
          <h3 className="title-main">Popular Locations</h3>
          <div className="d-grid grid-col-3 grid-element-9 margin-bottom">
            <div className="left-grid-ele-9 grid-bg3">
              <div className="sub-wid-grid-9">
                <h4 className="text-grid-9"><a href="#." onClick={(e) => { e.preventDefault(); e.persist(); movetoShops("Nagpur") }}>Nagpur</a></h4>
                <p className="sub-para">Deekshabhoomi Stupa</p>
              </div>
            </div>
            <div className="left-grid-ele-9 grid-bg4">
              <div className="sub-wid-grid-9">
                <h4 className="text-grid-9"><a href="#.">Wardha</a></h4>
                <p className="sub-para">Vishwa Shanti Stupa</p>
              </div>
            </div>
            <div className="left-grid-ele-9 grid-bg5">
              <div className="sub-wid-grid-9">
                <h4 className="text-grid-9"><a href="#.">Katol</a></h4>
                <p className="sub-para">Mata Anusaya Temple</p>
              </div>
            </div>
          </div>
          <div className="d-grid grid-col-2 grid-element-9">
            <div className="left-grid-ele-9 grid-bg1">
              <div className="sub-wid-grid-9">
                <h4 className="text-grid-9"><a href="#.">Saoner</a></h4>
                <p className="sub-para">Adasa Ganpati Temple</p>
              </div>
            </div>
            <div className="left-grid-ele-9 grid-bg2">
              <div className="sub-wid-grid-9">
                <h4 className="text-grid-9"><a href="#.">Kalmeshwar</a></h4>
                <p className="sub-para">Buddhawan Ramgiri</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}