import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import FranchiseeAuth from '../common/FranchiseeAuth';
import Select from 'react-select';
import Cities from "../cities.json"
//import Cities from '../common/Cities';

function Profile() {
  const cities = Cities;
  const [pageloading, setpageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState();
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState({});
  //const cities = Cities;
  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('franchisee-token'));
    setpageLoading(true);
    const fetchdata = async () => {
      window.$('.select2').select2();
      await Api.post('franchisee/profile', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            //console.log(result.data.data);
            setCategories(result.data.categories);
            setProfile(result.data.data);
          }
        });
    };
    fetchdata();
  }, []);

  const handleChange = (event) => {
    //To stop default events    
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setProfile({ ...profile, [name]: value, })
  }

  const handleCity = (event) => {
    let city = event.value;
    setProfile({ ...profile, ["franchisee_city"]: city, })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    //submitValidate(profile, errors);
    setLoading(true);
    await Api.post('franchisee/update', jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        setLoading(false);
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          setErrors(result.data.errors);
        } else if (status === 'success') {
          localStorage.setItem('franchisee-token', JSON.stringify(result.data.data));
          toast.success('your profile updated sucessfully !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        }
      });
  }

  return (
    <>
      <FranchiseeAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">My Profile</h3>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">First Name</label>
                              <input type="text" name="franchisee_name" value={profile.franchisee_name} onChange={handleChange} className={(errors.franchisee_name) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Shop Name" />
                              {errors.franchisee_name && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_name}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Categories</label>
                              <select name="category_id" onChange={handleChange} defaultValue={profile.category_id} className={(errors.category_id) ? 'form-control is-invalid' : 'form-control'}>
                                <option value="" >--select category--</option>
                                {categories && categories.map((category, index) =>
                                  <option key={index} value={category.id} >{category.name}</option>
                                )
                                }
                              </select>
                              {errors.category_id && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.category_id}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Email</label>
                              <input type="text" name="email" value={profile.email} onChange={handleChange} className={(errors.email) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Email" />
                              {errors.email && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.email}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Office No</label>
                              <input type="text" name="office_no" value={profile.office_no} onChange={handleChange} className={(errors.office_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Office No" />
                              {errors.office_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.office_no}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Aadhar No</label>
                              <input type="text" name="aadhar_no" value={profile.aadhar_no} onChange={handleChange} className={(errors.aadhar_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Aadhar No" />
                              {errors.aadhar_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.aadhar_no}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Pan No</label>
                              <input type="text" name="pan_no" value={profile.pan_no} onChange={handleChange} className={(errors.pan_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Pan No" />
                              {errors.pan_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.pan_no}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Franchisee State</label>
                              <select name="franchisee_state" onChange={handleChange} defaultValue={profile.franchisee_state} className={(errors.franchisee_state) ? 'form-control is-invalid' : 'form-control'}>
                                <option value="" >--select state--</option>
                                <option value="MAHARASHTRA">MAHARASHTRA</option>
                              </select>
                              {errors.franchisee_state && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_state}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group" data-select2-id="29">
                              <label>City</label>
                              <Select
                                className="basic-single"
                                classNamePrefix="select"
                                onChange={handleCity}
                                defaultValue={(profile.franchisee_city) ? { label: profile.franchisee_city, value: profile.franchisee_city } : null}
                                isDisabled={false}
                                isLoading={true}
                                isClearable={true}
                                isRtl={false}
                                isSearchable={true}
                                name="city"
                                options={cities}
                                placeholder="Select City"
                              />
                              {/* <select name="franchisee_city" style={{width:"100%"}} defaultValue={profile.franchisee_city} onChange={handleChange} className={(errors.franchisee_city) ? 'form-control select2 is-invalid' : 'form-control select2'} placeholder="Enter City" >
                              <option value="" >--select city--</option>
                                {cities.length !== undefined && cities.map((city,Index)=>(
                                  <option key={Index} value={city.name}>{city.name}</option>
                                ))}
                              </select> */}
                              {errors.franchisee_city && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_city}</span>}
                            </div>
                          </div>

                          {/* <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Franchisee City</label>
                              <input type="text" name="franchisee_city" value={profile.franchisee_city} onChange={handleChange} className={(errors.franchisee_city) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter City" />
                              {errors.franchisee_city && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_city}</span>}
                            </div>
                          </div> */}

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Franchisee Pincode</label>
                              <input type="text" name="franchisee_pincode" value={profile.franchisee_pincode} onChange={handleChange} className={(errors.franchisee_pincode) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Pincode" />
                              {errors.franchisee_pincode && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_pincode}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Franchisee Address</label>
                              <textarea type="text" name="franchisee_address" value={profile.franchisee_address} onChange={handleChange} className={(errors.franchisee_address) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Franchisee Address" />
                              {errors.franchisee_address && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.franchisee_address}</span>}
                            </div>
                          </div>

                        </div>
                      </div>

                      <div className="card-footer">
                        <button type="submit" className="btn button-eff">
                          <span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                        </button>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}

export default Profile