import React from 'react'
import { Link } from 'react-router-dom';
export default function InnerBanner(props) {
	return (
		<section className="w3l-inner-banner-main">
	    <div className="about-inner inner2">
	      <div className="wrapper seen-w3">
	        <ul className="breadcrumbs-custom-path">
	          <li><Link to="/">Home</Link></li>
	          <li><span className="fa fa-angle-right" aria-hidden="true"></span></li>
	          <li className="active">{props.title}</li>
	        </ul>
	      </div>
	    </div>
	  </section>
	)
}