import React, { useEffect, useState } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import {useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import InfiniteScroll from "react-infinite-scroll-component";

function Comments() {
  const data = useLocation();
  const [comments, setComments] = useState(null);
  const [count, setcount] = useState(0);
  const [counter, setCounter] = useState(0)
  const [news, setNews] = useState(null);
  const [news_id, setNews_id] = useState(0);
  const [pageloading, setpageLoading] = useState(false);

  useEffect(() => {
    const fetchdata = async () => {
      const values = data.state;
      setpageLoading(true);
      await Api.post('news/commentsall/0', jwt_encode(values, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          setpageLoading(false);
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setComments(result.data.data.comments);
            setNews(result.data.data.news);
            setcount(result.data.data.count);
            setNews_id(values.news_id);
          }
        })
    };
    fetchdata();
  }, []);

  const fetchmore = async () => {
    let offset = counter + 1;
    const data = { news_id: news_id }
    setTimeout(async () => {
      await Api.post('news/commentsall/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            //setpageLoading(false);
            let data = comments.concat(result.data.data.comments);
            setComments(data);
            setCounter(offset);
          }
        });
    }, 1500);
  }

  return (
    <>
      <Header />
      <ToastContainer />
      {(pageloading) ? <Loading /> :
        <>
          {comments !== null && comments !== 0 &&
            <section className="w3l-comments-9-main">
              <div className="gallery-32">
                <div className="wrapper">
                  <h3 className="title-main">{news}</h3>
                  <div className="grids-32-content">
                    <h3 className="title-main2">All Comments ({count})</h3>
                    <InfiniteScroll
                      dataLength={comments.length}
                      next={fetchmore}
                      hasMore={comments.length != count}
                      endMessage={
                        <p className="alert alert-success mt-4" style={{ textAlign: 'center' }}>
                          <b>Yay! You have seen it all</b>
                        </p>
                      }
                      loader={<Loading />}
                      scrollableTarget="scrollableDiv"
                    >
                      <div className="d-grid grid-columns">
                        {comments && comments.map((val, key) =>
                          <div className="box13" key={key}>
                            <div className="testi-w3pvt">
                              <div className="testi-agile">
                                <p>
                                  {val.comment}
                                </p>
                              </div>
                              <div className="testi-pos d-flex">
                                {/* <img src="assets/images/testi2.jpg" alt="" className="img-responsive rounded-circle mb-3" /> */}
                                <div className="right-coment">
                                  <h4>{val.name}</h4>
                                  <span>{val.created_at}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        )
                        }
                      </div>
                    </InfiniteScroll>
                  </div>
                </div>
              </div>
            </section>
          }
        </>
      }
      <Footer />
    </>
  )
}

export default Comments