import React from 'react'
import Loading from '../common/Loading';
import {useNavigate } from 'react-router-dom';
export default function Category(props) {
	const { categories, loading } = props;
	const navigate = useNavigate();
	const moveSingle = (shop_id) => {
    navigate('/shops',{state:{ search: '', category: shop_id, city: '' }});
  }
	return (
		<>
			<section className="w3l-category-main">
				<div className="categories-sec">
					<div className="wrapper">
						<h3 className="title-main">Popular Shops Categories</h3>
						{loading && <Loading />}
						<div className="right-models text-center">
							<div className="d-grid grid-sec">
								{!loading && categories.filter(category => category.popular === '1').map((category) => {
									return (
										<a href="." onClick={(e)=>{e.preventDefault(); e.persist();moveSingle(category.id)}} key={category.id}>
											<div className="card">
												<div className="card-body" style={{padding:"unset"}}>
													<span className={category.icon}></span>
													<h5 className="card-title" style={{lineHeight:"unset"}}>{category.name}</h5>
													<p className="para-design">{category.ads} Ads</p>
												</div>
											</div>
										</a>
									)
								})
								}
							</div>
						</div>
					</div>
				</div>
			</section>
		</>
	)
}
