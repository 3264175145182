import React,{Component} from 'react'
import jwt_decode from "jwt-decode";
import Header from '../common/Header.js'
import Footer from '../common/Footer.js'
import Banner from './Banner.js'
import Search from './Search.js'
import Category from './Category.js'
import Latest from './Latest.js'
import Locations from './Locations.js'
import Specifications from './Specifications.js'
import Choose from './Choose.js'
import News from './News.js'
//import axios from 'axios';
import Api from '../common/Api.js'
export default class Home extends Component {
  constructor (props) {
    super();
    this.state = {
      categories: [],
      cities : [],
      popularCategories : [],
      latest:[],
      featured :[],
      bestDeal:[],
      news:[],
      counts:[],
      loading : false
    };
  }

  async componentDidMount() {
    this.setState({loading:true});
    let customer_id = 0;
    if (localStorage.getItem('login-token') !== null) {
      const customer = JSON.parse(localStorage.getItem('login-token'));
      customer_id = customer.id;
    }
      
    await Api.get(`home/get_home_content/`+customer_id)
      .then(res => {
        const result = jwt_decode(res.data);
        const categories = result.data.categories;
        const popularCategories = result.data.popularCategories;
        const latest = result.data.latest;
        const featured = result.data.featured;
        const bestDeal = result.data.bestDeal;
        const news = result.data.news;
        const counts = result.data.counts;
        this.setState({categories,popularCategories,latest,featured,bestDeal,news,counts,loading:false});
      })
  }

	render() {
    return (
  		<>
  		  <Header/>
        <Banner/>
        <Search categories={this.state.categories} loading={this.state.loading}/>
        <Category categories={this.state.popularCategories} loading={this.state.loading}/>
        <Latest latest={this.state.latest} featured={this.state.featured} bestDeal={this.state.bestDeal} loading={this.state.loading}/>
        <Locations/>
        <Specifications counts={this.state.counts} loading={this.state.loading}/>
        <Choose/>
        <News news={this.state.news} loading={this.state.loading}/>
        <Footer/>
  		</>
	  )
  }
}