import React, { useState } from 'react'
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Header from '../common/Header.js';
import { toast, ToastContainer } from 'react-toastify';
import Footer from '../common/Footer.js';

const Contacts = () => {
  const [contact, setContact] = useState({ name: '', email: '', mobile: '', subject: '', query: '' });
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleChnage = (event) => {
    event.preventDefault();
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setContact({ ...contact, [name]: value, })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    setLoading(true);
    await Api.post('others/contacts', jwt_encode(contact, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        setLoading(false);
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          //toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          setErrors(data_error);
        } else if (status === 'success') {
          let res = result.data.data;
          setErrors({});
          setContact({ name: '', email: '', mobile: '', subject: '', query: '' });
          toast.success(res.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        }
      });
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <div className="w3l-contact-main">
        <div className="contact sec-padding">
          <div className="wrapper">
            <h3 className="title-main">Contact Us</h3>
            <div className="contact-form mx-auto pt-sm-4">
              <form onSubmit={handleSubmit}>
                <div className="d-grid">
                  <div className="form-group">
                    <label htmlFor="w3lName">Name</label>
                    <input type="text" className="form-control" onChange={handleChnage} name="name" value={contact.name} id="w3lName" placeholder="Enter your name.." />
                    {errors.name && <small className="text-danger">{errors.name}</small>}
                  </div>
                  <div className="form-group">
                    <label htmlFor="w3lSender">Mobile</label>
                    <input type="text" className="form-control" onChange={handleChnage} name="mobile" value={contact.mobile} id="w3lSender" placeholder="Enter your mobile.." />
                    {errors.mobile && <small className="text-danger">{errors.mobile}</small>}
                  </div>
                </div>
                <div className="form-group">
                  <label htmlFor="w3lSender">Email</label>
                  <input type="text" className="form-control" onChange={handleChnage} name="email" value={contact.email} id="w3lSender" placeholder="Enter your email.." />
                  {errors.email && <small className="text-danger">{errors.email}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="w3lSubject">Subject</label>
                  <input type="text" className="form-control" onChange={handleChnage} name="subject" value={contact.subject} id="w3lSubject" placeholder="Enter subject.." />
                  {errors.subject && <small className="text-danger">{errors.subject}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="w3lSubject">How can we help?</label>
                  <textarea name="query" onChange={handleChnage} value={contact.query} id="w3lMessage" className="form-control" placeholder="Enter query here.." />
                  {errors.query && <small className="text-danger">{errors.query}</small>}
                </div>
                <button type="submit" className="btn button-eff button-eff-2">
                  <span style={{ marginRight: "0px",marginLeft:"25px"}}>Submit</span><span style={{ float: "right", width: "10px", height: "10px",marginLeft:"20px"}}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                </button>
              </form>
            </div>
            <div className="d-grid temp-cont-grid">
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-map-marker"></span>
                </div>
                <div className="text-left">
                  <h6>Location</h6>
                  <p>DREAMS LINKAGE MARKETING PRO 21 (I) (OPC) PRIVATE LIMITED,
                    <br />Corporate Office : Suprabhat Sankul - Near Mini Punjab Restaurant, C/o Purushottam Super Bazar, Dharampeth Extension, NAGPUR - 10</p>
                </div>
              </div>
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-envelope-o"></span>
                </div>
                <div className="text-left">
                  <h6>Email</h6>
                  <a href="mailto:info@example.com">support@example.com</a>
                </div>
              </div>
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-phone"></span>
                </div>
                <div className="text-left">
                  <h6>Phone Number</h6>
                  <p><a href="tel:8856819762">+91 88568 19762</a></p>
                  <p><a href="tel:9545501071">+91 95455 01071</a></p>
                </div>
              </div>
            </div>
            <div className="map">
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d317718.69319292053!2d-0.3817765050863085!3d51.528307984912544!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47d8a00baf21de75%3A0x52963a5addd52a99!2sLondon%2C%20UK!5e0!3m2!1sen!2sin!4v1572068242531!5m2!1sen!2sin"
                width="100%" frameBorder="0" style={{ border: "0" }} allowFullScreen="" title='Map'></iframe>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contacts