import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import { useNavigate} from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import default_image from '../default-image.png';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import CustomerAuth from '../common/CustomerAuth';
import InfiniteScroll from "react-infinite-scroll-component";
const Favorites = () => {
  const navigate = useNavigate();
  const [pageloading, setpageLoading] = useState(false);
  const customer = JSON.parse(localStorage.getItem('login-token'));
  const [shops, setShops] = useState(0);
  const [count, setcount] = useState(0);
  const [counter, setCounter] = useState(0)

  useEffect(() => {
    const fetchdata = async () => {
      setpageLoading(true);
      await Api.post('shops/favorites', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setShops(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setShops(result.data.shops);
            setcount(result.data.count);
          }
        });
    };
    fetchdata();
  }, [])

  const getdata = async () => {
    setpageLoading(true);
    await Api.post('shops/favorites', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          setpageLoading(false);
          setShops(null);
          toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          setpageLoading(false);
          setShops(result.data.shops);
          setcount(result.data.count);
        }
      });
  }

  const fetchmore = async () => {
    let offset = counter + 1;
    setTimeout(async () => {
      await Api.post('shops/favorites/' + offset, jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            //setpageLoading(false);
            let data = shops.concat(result.data.shops);
            setShops(data);
            setCounter(offset);
          }
        });
    }, 1500);
  }

  const moveSingle = (shop_id) => {
    navigate('/single', { state: { id: shop_id } });
  }

  const addFavorite = async (event, shop_id) => {
    if (localStorage.getItem('login-token') !== null) {
      const customer = JSON.parse(localStorage.getItem('login-token'));
      let data = { customer_id: customer.id, franchisee_id: shop_id };
      await Api.post('customers/favorites', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            toast.error(result.data.error, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            toast.success(result.data.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
            getdata();
          }
        });
    } else {
      toast.error('Please login to add favorite !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }
  }

  return (
    <>
      <CustomerAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-products-page w3l-blog-single w3l-products-4">
        <div className="single blog">
          <div className="wrapper">
            <h3 className="title-main">Favorites Shops</h3>
            <div className="d-grid">
              <div className="tab-content text-left">
                {(shops !== null && shops !== 0 && shops.length > 0) ?
                  <InfiniteScroll
                    dataLength={shops.length}
                    next={fetchmore}
                    hasMore={shops.length != count}
                    endMessage={
                      <p className="alert alert-success mt-4" style={{ textAlign: 'center' }}>
                        <b>Yay! You have seen it all</b>
                      </p>
                    }
                    loader={<Loading />}
                    scrollableTarget="scrollableDiv"
                  >
                    <div className="d-grid grid-col-3">
                      {
                        shops.map((shop, shopIndex) =>
                          <div className="product" key={shopIndex}>
                            <a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}><img src={(shop.image) ? shop.image : default_image} className="img-responsive"
                              alt={shop.name} /></a>
                            <div className="info-bg">
                              <h5><a href={"."} onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}>{shop.name}</a></h5>
                              <p className='mb-0'>{shop.city} - {shop.pincode}</p>
                              <p className='mt-0' style={{ fontSize: "12px" }}>{shop.address.slice(0, 45)}...</p>
                              <ul className="d-flex">
                                <li><a href="." onClick={(e) => { e.preventDefault(); e.persist(); moveSingle(shop.id) }}><span className="fa fa-eye"></span> Explore Now</a></li>
                                <li className="margin-effe"><a href='#.' title="Favorite Shops List"><span onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, shop.id) }} className="fa fa-heart"></span></a></li>
                                <li><a href="#sahre" title="Share"><span className="fa fa-share"></span></a></li>
                              </ul>
                            </div>
                          </div>
                        )
                      }
                    </div>
                  </InfiniteScroll>
                  :
                  <div className="d-grid grid-col-3" style={{ textAlign: "center" }}>
                    <center><span><strong>Shops Not Found !!!</strong></span></center>
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Favorites