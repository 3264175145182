import React from 'react'
import Header from '../common/Header.js'
import InnerBanner from '../common/InnerBanner.js'
import Footer from '../common/Footer.js'
import { Link } from 'react-router-dom';
export default function HowItsWorks() {
	return (
		<React.Fragment>
			<Header/>
      <InnerBanner title={'How Its Works'} />
			<section className="w3l-gallery-25-top">
		    <div className="gallery-25">
		      <div className="wrapper">
		        <h3 className="title-main">How its work?</h3>
		        <div className="gallery-25-content">
		          <div className="d-grid grid-columns">
		            <div className="column">
		              <div className="box13">
									<Link to="/register"><img className="side-img img-fluid img-responsive" src="assets/images/service1.jpg"
		                    alt="" /></Link>
		              </div>
		            </div>
		            <div className="column two">
		              <div className="box13">
		                <span>01</span>
		                <h3><Link to="/register">First Create Your Account</Link></h3>
		                <p>Enter your First and Last name etc.. in the appropriate boxes, then click on submit. By creating an account you agree to our Terms of Service and have read our Privacy Policy.</p>
		                <Link to="/register" className="action btn button-eff">Create Now</Link>
		              </div>
		            </div>
		            <div className="column two col1">
		              <div className="box13">
		                <span>02</span>
		                <h3><Link to="/shops">Search Nearest Shops</Link></h3>
		                <p>You can search for areas of interest like electronics, fashion, food, groceries etc.</p>
		                <Link to="/shops" className="action btn button-eff">Search Now</Link>
		              </div>
		            </div>
		            <div className="column col2">
		              <div className="box13">
									<Link to="/shops" ><img className="side-img img-fluid img-responsive" src="assets/images/service2.jpg"
		                    alt="" /></Link>
		              </div>
		            </div>
		            <div className="column">
		              <div className="box13">
									<Link to="/shops"><img className="side-img img-fluid img-responsive" src="assets/images/service3.jpg"
		                    alt="" /></Link>
		              </div>
		            </div>
		            <div className="column two">
		              <div className="box13">
		                <span>03</span>
		                <h3><a href="login.html">Get Your Best Offers & Purchase</a></h3>
		                <p>We’ll look at some of the most popular ways you can use discounts to drive customer loyalty and conversions and, of course, how to most effectively use them. Purchase your item and ask retailer to update your purchase on DLMP21.</p>
		                <Link to="/shops" className="action btn button-eff">Best Deals</Link>
		              </div>
		            </div>
		            <div className="column two col1">
		              <div className="box13">
		                <span>04</span>
		                <h3><a href="login.html">Check MLM Stage & Get Commisions</a></h3>
		                <p>Check your DLMP21 MLM Stage and purchase minimum 1000 Rs items to get commisions. Commisions will be send to your direct account every first week of month.</p>
		                <Link to="/commisions" className="action btn button-eff">Get Commisions</Link>
		              </div>
		            </div>
		            <div className="column col2">
		              <div className="box13">
									  <Link to="/commisions"><img className="side-img img-fluid img-responsive" src="assets/images/service4.jpg"
		                    alt="" /></Link>
		              </div>
		            </div>
		          </div>
		        </div>
		      </div>
		    </div>
	    </section>
      <Footer/>
    </React.Fragment>
	)
}