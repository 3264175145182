import React from 'react'
import Header from '../common/Header.js'
import InnerBanner from '../common/InnerBanner.js'
import Footer from '../common/Footer.js'
const Terms = () => {
  return (
    <>
      <Header />
      <InnerBanner title={'Terms of Services'} />
      <div className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="faq-cont">
              <div className="about-right-faq">
                <div className="bg-warning text-dark"><b>Warning :</b> Please, Read Carefully before avail the services.</div>
                <p className="all-terms">Welcome to the DLMP21. The following terms and conditions and any other policies or guidelines are
                  posted by Dlmp21.com website/app/app. If you shop or
                  visit at www.dlmp21.com, you accept these terms and
                  conditions. Visitors and shoppers are advised to read
                  these Terms and Conditions carefully before using this
                  site/app/app or purchasing any of the products or using
                  any of the services available on or through it. These
                  Terms and Conditions may be changed in the future
                  without prior notice to you. So please check back every
                  time you use the services. Your continued use of this
                  site/app/app after such changes constitutes your
                  acceptance of the new Terms and Conditions.</p>
                <br />
                <h4 className="head-terms"><b>GENERAL</b></h4>
                <p className="all-terms" >a) We shall mean “Dlmp21.com”, is the website/app or
                  service offered by the Dreams Linkage Marketing Pro 21
                  (I)(OPC)PVT.LTD. Customer shall mean ‘you’ and
                  anyone else use this website/app on your behalf including
                  minors. Business Partners shall mean products’
                  manufacture, suppliers, sponsors and our related business
                  contacts.
                  <br /> b) To register and shop with DLMP21 you must
                  be Eighteen years of age and above. DLMP21 has
                  the right to refuse the registration and sale of any potential
                  customer for any reason and to terminate the registration
                  of any of its customers without assigning any reason. By
                  placing order on this website/app the person undertakes
                  he is above 18 years of age.
                  <br />c) While every care is taken to ensure that product
                  illustration and information are as accurate as possible,
                  the photograph and images that are shown are only
                  representation of actual products and may differ from
                  actual.
                  <br />d) These Product Terms shall be governed by and
                  construed in accordance with the applicable laws in India.
                  This site/app is controlled and operated by Dreams
                  Linkage Marketing Pro 21 (I)(OPC)PVT.LTD from its
                  offices within the “Nagpur, Maharashtra, India”. Those
                  who choose to access this site/app from other locations do
                  so on their own initiative and are responsible for
                  compliance with local laws, if and to the extent local laws
                  are applicable. Dreams Linkage Marketing Pro 21
                  (I)(OPC)PVT.LTD makes no representation that materials
                  or other content on DLMP21 are appropriate or
                  available for use outside the India, its territories,
                  possessions and protectorates. If you choose to access
                  DLMP21 from other locations you do so on your
                  own initiative and at your own risk. You are responsible
                  for complying with local laws, if and to the extent local
                  laws are applicable. You specifically agree to comply
                  with all applicable laws concerning the transmission of
                  technical data exported from the India or the country you
                  reside in.</p>
                <p className="all-terms" >If any of these Product Terms is held by any court of
                  competent authority to be unlawful, invalid or
                  unenforceable, in whole or in part, this will not affect the
                  validity of the remaining Product Terms which will
                  continue to be valid and enforceable to the fullest extent
                  permitted by law.</p>
                <p className="all-terms">The law places certain restrictions on transactions
                  involving products, products must be ordered by you and
                  if you are not at home at your selected delivery time, it is
                  your responsibility to ensure that a suitable person (above
                  18 years old)/responsible is there to receive the order.</p>
                <br />
                <h4 className="head-terms"><b>USE OF THIS SITE/APP</b></h4>
                <p className="all-terms">DLMP21 grants you to access, browse and make
                  personal use of this site/app and NOT to download and/or
                  modify it, of any portion of it, examples; use of any
                  product listings, descriptions, or prices; any derivative use
                  of this Site/app or its contents; any downloading or
                  copying of account information for the benefit of another
                  merchant; or any use of data mining, robots, or similar
                  data gathering and extraction tools without express
                  written consent of Dreams Linkage Marketing Pro 21
                  (I)(OPC)PVT.LTD. This Site/app or any portion of it may
                  not be reproduced, duplicated, copied, sold, resold, or
                  otherwise exploited for any commercial purpose, without
                  express written consent from us. You may not frame or
                  enclose or use any trademark, logo, or other proprietary

                  information (including images, text, page layout, or form)
                  of DLMP21 without obtaining express written
                  consent. You may not use any Meta tags or any other
                  &quot;hidden text&quot; utilizing our company and products’ name,
                  logos or trademarks without the express written consent
                  of Dreams Linkage Marketing Pro 21 (I)(OPC)PVT.LTD.
                  We reserve the right to terminate any unauthorized use
                  granted by us. You are granted a limited, revocable, and
                  nonexclusive right to create a hyperlink to any page of
                  DLMP21 so long as the link does not portray its
                  products or services in a false, misleading, derogatory, or
                  otherwise offensive matter. You may not use any the
                  Company logo or other proprietary graphic or trademark
                  as part of the link without express written permission.</p>
                <br />
                <h4 className="head-terms"><b>PROPRIETARY RIGHTS</b></h4>
                <p className="all-terms">All content included on this Site/app, such as products,
                  text, graphics, logos, button icons, images, audio clips,
                  digital downloads, data compilations, and software
                  (&quot;Content&quot;), is the property of KiranaOli.com or our
                  suppliers, sponsors or affiliates (collectively, &quot;Business
                  Partners&quot;), and is protected by India and international
                  copyright law, trademark law, and trade secret law, as
                  well as other state, federal and international laws and
                  regulations. We own a copyright in this Site/app as a
                  collective work; products’ information, product’s picture
                  and all other information compilation, and in the
                  selection, coordination and arrangement of the Content.

                  Neither we nor our Business Partners grant any rights to
                  you under any patents, copyrights, trademarks or trade
                  secret information. In addition, we do not grant any rights
                  to any of our Business Partners the use of our collective
                  work without prior written permission. Under no
                  circumstances will DLMP21 be liable in any way
                  for any Content, including, but not limited to, for any
                  errors or omissions in any Content, or for any loss or
                  damage of any kind incurred as a result of the use of any
                  Content made available via the Site/app. You agree that
                  you must evaluate, and bear all risks associated with, the
                  use of any Content, including any reliance on the
                  accuracy, completeness, or usefulness of such Content.</p>
                <br />
                <h4 className="head-terms"><b>USER PRIVACY</b></h4>
                <p className="all-terms" >We believe in protecting user privacy. For that reason, we have adopted a privacy policy. You should refer to our
                  Privacy Policy for further information about how we use
                  and collect personal information from users of this
                  Site/app.</p>
                <br />
                <h4 className="head-terms"><b>OFFERS & PRICES</b></h4>
                <p className="all-terms" >Offers, promotional prices, special deals all are Subject to availability. At all time, we will ensure that the prices,
                  details and sizes of products are correct. However, we
                  reserve the right to vary the prices and other details of the
                  products without advance notice to customers (due to
                  daily price fluctuations, discrepancies of product prices
                  and its availability listed on this site/app are subject to
                  change without any prior notice).</p>
                <p className="all-terms" >Every effort is made to ensure accurate and most recent
                  information about our products will be reflected on
                  printed material and on our website/app. The invoice that
                  comes with the delivery will contain the most updated
                  prices. Any discrepancies may be due to out-of- stock,
                  substitutions, special offers etc. by our Business Partners.
                  You may reject the particular product upon delivery. We
                  will not be liable to make any compensation due to price
                  differences. We reserve the right at any time after receipt
                  of your order to accept or decline your order, the right to
                  adjust the final bill after it is presented to the Customer
                  and such adjustments shall be make known to the
                  Customer at the time of delivery. We also reserve the
                  right to limit sales to any individual customers.</p>
                <p className="all-terms" >Prices are subject to change without any prior notice to
                  the customers. If prices are changed before order process,
                  the same price will be applicable to the order also.</p>
                <p className="all-terms" >We deliver you a free service, if and only if you made an
                  order of more than or equal to 1000 rupees, otherwise
                  shipping charges are added.</p>
                <p className="all-terms">Since you can place an order up to 2 days in advance from
                  the actual delivery date we need to accommodate for
                  these price fluctuations and therefore any difference in the
                  bill value on the date of placing the order and the bill
                  value as per the prices applicable on the date of delivery
                  will be collected / refunded in cash at the time of the
                  delivery of the order.</p>
                <br />
                <h4 className="head-terms"><b>AVAILABILITY</b></h4>
                <p className="all-terms">If for any reason beyond our reasonable control, we are
                  unable to supply a particular item, we will not be liable to
                  you. Please note that we may attempt to deliver substitute
                  lines should selected items be unavailable unless you
                  specifically request us not to do so.</p>
                <br />
                <h4 className="head-terms"><b>ACCEPTANCE</b></h4>
                <p className="all-terms">There will be no contract of any kind between you and us
                  unless and until we actually dispatch the goods to you.
                  Your order is an offer to buy from us. Nothing that we do
                  or say will amount to any acceptance of that offer until we
                  actually dispatch the goods to you. At any point up until
                  then, we may decline to supply the goods to you without
                  giving any reason. At the moment that the goods are
                  dispatched a contract will be made between you and us,
                  and you will be charged for the goods. This website/app is
                  a medium of procurement of order only. Placing an order
                  on the website/app does not bind in any contract unless
                  and until it is accepted by us and initiation of delivery. To
                  ensure availability of all our products customers may be
                  limited to a maximum number of items. Management
                  Reserves the right to accept the order or to reject it.
                  Dreams Linkage Marketing Pro 21 (I)(OPC)PVT.LTD.
                  Reserves the right to honor any discounts, offers, coupons
                  and order overriding all the other terms and conditions.</p>

                {/* <h4 className="head-terms"><b>DELIVERY & SHIPPING POLICY</b></h4>
                                  <p className="all-terms">For delivery and shipping details please refer to our Delivery Information page.</p> */}

                {/* <h4 className="head-terms"><b>RETURN POLICY</b></h4>
                                    <p className="all-terms">We hope you will be happy with your purchase. However, should you feel it necessary to return an item, we aim to make the returns process as simple as possible. We have a ‘NO QUESTIONS ASKED POLICY’.<br/>You can return the item on the time of delivery if,<br/>a. Item is defected <br/>b. Item is expired <br/>c. Item is that you are not ordered <br/>We will not charge you for any incorrect goods or defective goods which you have received.</p> */}

                {/* <h4 className="head-terms"><b>CANCELLATION</b></h4>
                                        <p className="all-terms">You can cancel the whole order within two hours of after order placed, if you want to cancel the whole order after two hours
                                          from order placed, you need to call on company contact number
                                          within the company business hour of till 7 pm on the same day
                                          of order placed. If you cancelled the order or no one is available
                                          to receive the order at the time of delivery you should pay the
                                          cancellation charges of One Hundred Rupees or it will be
                                          deducted from your commission as well.</p> */}

                {/* <h4 className="head-terms"><b>PAYMENT</b></h4>
                                          <p className="all-terms">Right now our process is working on cash on delivery theme. You should pay the correct amount as in the invoice. we accept only Indian rupees.</p> */}
                <br />
                <h4 className="head-terms"><b>REVIEWS, FEEDBACK, SUBMISSIONS</b></h4>
                <p className="all-terms">All reviews, comments, feedback, postcards, suggestions,
                  ideas, and other submissions disclosed, submitted or
                  offered to us or by this Website/app or otherwise
                  disclosed, submitted or offered in connection with your
                  use of this Website/app (collectively, the &quot;Comments&quot;)
                  shall be and remain our property. Such disclosure,
                  submission or offer of any Comments shall constitute an
                  assignment to us of all worldwide rights, titles and
                  interests in all copyrights and other intellectual properties
                  in the Comments. Thus, We own exclusively all such
                  rights, titles and interests and shall not be limited in any
                  way in its use, commercial or otherwise, of any
                  Comments. We will be entitled to use, reproduce,
                  disclose, modify, adapt, create derivative works from,
                  publish, display and distribute any Comments you submit
                  for any purpose whatsoever, without restriction and
                  without compensating you in any way. We are and shall
                  be under no obligation (1) to maintain any Comments in
                  confidence; (2) to pay you any compensation for any
                  Comments; or (3) to respond to any Comments. You
                  agree that any Comments submitted by you to the
                  Website/app will not violate this policy or any right of

                  any third party, including copyright, trademark, privacy or
                  other personal or proprietary right(s), and will not cause
                  injury to any person or entity. You further agree that no
                  Comments submitted by you to the Website/app will be or
                  contain libelous or otherwise unlawful, threatening,
                  abusive or obscene material, or contain software viruses,
                  political campaigning, commercial solicitation, chain
                  letters, mass mailings or any form of &quot;spam&quot;.</p>
                <p className="all-terms">We do not regularly review posted Comments, but do
                  reserve the right (but not the obligation) to monitor and
                  edit or remove any Comments submitted to the Site/app.
                  You grant DLMP21 the right to use the name that
                  you submit in connection with any Comments. You agree
                  not to use a false email address, impersonate any person
                  or entity, or otherwise mislead as to the origin of any
                  Comments you submit. You are and shall remain solely
                  responsible for the content of any Comments you make
                  and you agree to indemnify we and our affiliates for all
                  claims resulting from any Comments you submit. We and
                  our affiliates take no responsibility and assume no
                  liability for any Comments submitted by you or any third
                  party.</p>
                <br />
                <h4 className="head-terms"><b>INDEMNITY</b></h4>
                <p className="all-terms">You agree to defend, indemnify and hold harmless Dreams
                  Linkage Marketing Pro 21 (I)(OPC) PVT.LTD., its employees,
                  directors, officers, agents and their successors and assigns
                  from and against any and all claims, liabilities, damages,
                  losses, costs and expenses, including attorney&#39;s fees,
                  caused by or arising out of claims based upon your
                  actions or inactions, which may result in any loss or
                  liability to Dreams Linkage Marketing Pro 21 (I)(OPC)
                  PVT.LTD. or any third party including but not limited to
                  breach of any warranties, representations or undertakings
                  or in relation to the non-fulfillment of any of your
                  obligations under this User Agreement or arising out of
                  the your violation of any applicable laws, regulations
                  including but not limited to Intellectual Property Rights,
                  payment of statutory dues and taxes, claim of libel,
                  defamation, violation of rights of privacy or publicity,
                  loss of service by other subscribers and infringement of
                  intellectual property or other rights. This clause shall
                  survive the expiry or termination of this User Agreement.</p>
                <br />
                <h4 className="head-terms">Customer Services</h4>
                <p className="all-terms">• Customer Care: +91 919545501071</p>
                <p className="all-terms">• Email: support@dlmp21.com</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Terms