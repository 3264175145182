import React, { useState } from 'react'
import Loading from '../common/Loading';
import {useNavigate } from 'react-router-dom';
export default function News (props){
    let {news,loading} = props;
    const navigate = useNavigate();
    const moveSingle = (news_id) => {
      navigate('/news-read',{state:{id:news_id}});
    }
    return (
		<div className="w3l-new-block-6">
        <section id="grids5-block">
            <div className="wrapper">
                <h3 className="title-main">Our latest news</h3>
                {loading && <Loading/>}
                <div className="d-grid">
                    {
                       !loading && news.map((news)=>{
                          return (
                            <div className="grids5-info" key={news.id+'news'}>
                                <a href={".#"} onClick={(e)=>{e.preventDefault(); e.persist();moveSingle(news.id)}}><img src={news.image} alt=""/></a>
                                <h4><a href="#." onClick={(e)=>{e.preventDefault(); e.persist();moveSingle(news.id)}}>{news.title}</a></h4>
                                <ul className="admin-list">
                                    <li><a href=".#"><span className="fa fa-user" aria-hidden="true"></span>by
                                            Admin</a></li>
                                    <li><a href={".#"}><span className=" fa fa-comments-o" aria-hidden="true"></span>{news.comments} Comments</a></li>
                                </ul>
                                <p>{news.news.toString().replace(/(<([^>]+)>)/ig, '').trim().slice(0,123)}...</p>
                            </div>
                          )
                       })
                    }
                    
                </div>
            </div>
        </section>
    </div>
	)
}
