import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import CustomerAuth from '../common/CustomerAuth';

export default function Commissions() {
  const [pageloading, setpageLoading] = useState(false);
  const [commision, setCommision] = useState(null);
  const [count, setcount] = useState(null);
  const [counter, setCounter] = useState(0)
  useEffect(() => {
    const fetchdata = async () => {
      let customer = JSON.parse(localStorage.getItem('login-token'));
      const data = { customer: customer, filter: "all" }
      setpageLoading(true);
      await Api.post('customers/commision', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setCommision(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setCommision(result.data.data);
            setcount(result.data.count);
          }
        });
    };
    fetchdata();
  }, []);

  const handlePrevious = async () => {
    let number = counter - 1;
    setCounter(number);
    getdata(number);
  }

  const handleNext = async () => {
    let number = counter + 1;
    setCounter(number);
    getdata(number);
  }

  const getdata = async (offset = 0) => {
    let customer = JSON.parse(localStorage.getItem('login-token'));
    const data = { customer: customer }
    setpageLoading(true);
    await Api.post('customers/commision/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          setpageLoading(false);
          setCommision(null);
          toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          setpageLoading(false);
          setCommision(result.data.data);
          setcount(result.data.count);
        }
      });
  };

  return (
    <>
      <CustomerAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title mb-2">Commissions</h3>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <div className="card-body table-responsive p-0">
                      <table className="table m-0">
                        <thead>
                          <tr>
                            <th>Date</th>
                            <th>Purchase Amount</th>
                            <th>Commission</th>
                            <th>Status</th>
                          </tr>
                        </thead>
                        {(commision !== null) ?
                          <tbody>
                            {
                              commision.map((commision, commisionIndex) =>
                                <tr key={commisionIndex}>
                                  <td><strong>{commision.month}</strong></td>
                                  <td><span className='text-success'>{commision.amount}</span></td>
                                  <td><span className='text-info'>{commision.commision}</span></td>
                                  <td><span className="text-success">{commision.status}</span></td>
                                </tr>
                              )
                            }
                          </tbody>
                          :
                          <tbody>
                            <tr>
                              <td colSpan={3}>Commission not found !!!</td>
                            </tr>
                          </tbody>
                        }
                      </table>
                    </div>
                  }
                  <div className="card-footer clearfix">
                    <button disabled={(counter <= 0 ? true : false)} onClick={handlePrevious} className="btn btn-sm btn-info float-left">Previous</button>
                    <button disabled={(counter + 1) >= Math.ceil(count / 5)} onClick={handleNext} className="btn btn-sm btn-info float-right">Next</button>
                  </div>
                </div>
              </div>

            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}
