import React, { Component } from 'react'
import Loading from '../common/Loading';
export default class Specifications extends Component {
  render() {
    let counts = this.props.counts;
    let loading = this.props.loading;
	return (
		<section className="w3l-specifications-9">
        <div className="main-w3">
            <div className="overlay">
                <div className="wrapper">
                    {(loading) ? <Loading/> :
                        <div className="d-flex main-cont-wthree-fea text-center">
                            <div className="grids-speci">
                                <div className="stats-icon">
                                    <span className="fa fa-bullhorn" aria-hidden="true"></span>
                                </div>
                                <div>
                                    <h3 className="title-spe">{counts.ads}+</h3>
                                    <p>Published Shops</p>
                                </div>
                            </div>
                            <div className="grids-speci">
                                <div className="stats-icon">
                                    <span className="fa fa-users" aria-hidden="true"></span>
                                </div>
                                <div>
                                    <h3 className="title-spe">{counts.register}+</h3>
                                    <p>Registered Customers</p>
                                </div>
                            </div>
                            <div className="grids-speci">
                                <div className="stats-icon">
                                    <span className="fa fa-thumbs-o-up" aria-hidden="true"></span>
                                </div>
                                <div>
                                    <h3 className="title-spe">{counts.verify}+</h3>
                                    <p>Verified Customers</p>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    </section>
	)
  }
}
