import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Header from '../common/Header.js';
import { toast, ToastContainer } from 'react-toastify';
import Footer from '../common/Footer.js';
import CustomerAuth from '../common/CustomerAuth';

export const Report = () => {
  const customer = JSON.parse(localStorage.getItem('login-token'));
  const [errsub, errSubject] = useState("");
  const [errrep, errReports] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = async(event) => {
    event.preventDefault();
    event.persist();
    let subject = event.target.subject.value;
    let reports = event.target.reports.value;
    if (subject.trim().length ===  0)
      errSubject("Please enter Subject.");
    if (reports.trim().length ===  0)
      errReports("Please enter your query.");
    
    if (subject.trim().length > 0 && reports.trim().length > 0) {
      const data = {customer_id:customer.id,subject:subject,reports:reports};
      setLoading(true);
      await Api.post('others/reports', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          setLoading(false);
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            let data_error = result.data.data;
            event.target.subject.value = "";
            event.target.reports.value = "";
            errSubject("");
            errReports("");
            toast.success(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          }
        });
    } 
  }

  return (
    <>
      <CustomerAuth/>
      <Header />
      <ToastContainer />
      <div className="w3l-contact-main">
        <div className="contact sec-padding">
          <div className="wrapper">
            <h3 className="title-main">Report Fraud</h3>
            <div className="contact-form mx-auto pt-sm-4">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <label htmlFor="w3lSubject">Subject</label>
                  <input type="text" className="form-control" name="subject" id="w3lSubject" placeholder="Enter subject here..." />
                  {errsub && <small className="text-danger">{errsub}</small>}
                </div>
                <div className="form-group">
                  <label htmlFor="w3lSubject">How can we help ?</label>
                  <textarea name="reports" id="w3lMessage" className="form-control" placeholder="Enter your query here..." />
                  {errrep && <small className="text-danger">{errrep}</small>}
                </div>

                <button type="submit" className="btn button-eff button-eff-2">
                  <span style={{ marginRight: "0px",marginLeft:"25px"}}>Submit</span><span style={{ float: "right", width: "10px", height: "10px",marginLeft:"20px"}}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                </button>

              </form>
            </div>
            <div className="d-grid temp-cont-grid">
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-map-marker"></span>
                </div>
                <div className="text-left">
                  <h6>Location</h6>
                  <p>The company name,
                    <br />London. </p>
                </div>
              </div>
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-envelope-o"></span>
                </div>
                <div className="text-left">
                  <h6>Email</h6>
                  <a href="mailto:info@example.com">mail@example.com</a>
                  <br />
                  <a href="mailto:info@example.com">mail2@example.com</a>
                </div>
              </div>
              <div className="d-grid adress-info">
                <div className="adress-icon">
                  <span className="fa fa-phone"></span>
                </div>
                <div className="text-left">
                  <h6>Phone Number</h6>
                  <p><a href="tel:+1-234-567-0890">+1-234-567-0890</a></p>
                  <p><a href="tel:+44-000-888-999">+44-000-888-999</a></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
