import React, { useEffect, useState } from 'react'
import { useNavigate,Link } from 'react-router-dom';
import Api from '../common/Api.js'
import jwt_decode from "jwt-decode";
export default function Header(props) {
  const customer = JSON.parse(localStorage.getItem('login-token'));
  const franchisee = JSON.parse(localStorage.getItem('franchisee-token'));
  const [categories, setCategories] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const fetchdata = async () => {
      await Api.get(`home/get_categories/0`)
        .then(res => {
          let result = jwt_decode(res.data);
          setCategories(result.data);
        })
    };
    fetchdata();
  },[]);

  const movetoShops = (category_id) => {
	  const search = { search: '', category: category_id, city: '',customer:(customer!==null)?customer.id:0};
    navigate('/shops', { state: search });
  }

  return (
    <div className="w3l-headers-9">
      <header>
        <div className="wrapper">
          <div className="header">
            <div className="right-img-9">
              <h1>
                <Link to="/" className="logo" style={{ color: "#ec0009", fontWeight: "700" }}>DLMP21</Link>
              </h1>
            </div>
            <div className="bottom-menu-content">
              <input type="checkbox" id="nav" />
              <label style={{ display: "inline" }} htmlFor="nav" className="menu-bar"></label>
              <nav>
                <div className="wrapper">
                  <ul className="menu">
                    <li><Link to="/" className="link-nav">Home</Link></li>
                    <li><Link to="/how-its-works" className="link-nav">How it Works?</Link></li>
                    <li>
                      <label htmlFor="drop-1" className="toggle toogle-1">All Ads <span
                        className="fa fa-angle-down" aria-hidden="true"></span></label>
                      <Link to="#blog" className="link-nav dropdown-toggle">All Ads <span
                        className="fa fa-angle-down" aria-hidden="true"></span></Link>
                      <input type="checkbox" id="drop-1" />
                      <ul className="sec-drop">
                        <li><Link to="/shops">All Ads</Link></li>
                        {
                         categories.length !== 0 && categories.map((category,index)=>
                          <li key={index}><a href=".#" onClick={(e) => { e.preventDefault(); e.persist(); movetoShops(category.id) }}>{category.name}</a></li>
                          )
                        }
                      </ul>
                    </li>

                    <li><Link to="/news" className="link-nav">News</Link></li>

                    {
                      localStorage.getItem('login-token') !== null &&
                      <li>
                        <label htmlFor="drop-4" className="toggle toogle-2"><span
                          className="fa fa-user-circle-o" aria-hidden="true"></span> {customer.f_name} <span className="fa fa-angle-down"
                            aria-hidden="true"></span></label>
                        <Link to="#" className="link-nav dropdown-toggle"> <span
                          className="fa fa-user-circle-o" aria-hidden="true"></span> {customer.f_name} <span
                            className="fa fa-angle-down" aria-hidden="true"></span></Link>
                        <input type="checkbox" id="drop-4" />
                        <ul>
                          <li><Link to="/dashboard">Dashboard</Link></li>
                          <li><Link to="/profile">My Profile</Link></li>
                          <li><Link to="/favorites">Favorites</Link></li>
                          <li><Link to="/purchase">Purchase</Link></li>
                          <li><Link to="/downline">Downline</Link></li>
                          <li><Link to="/kyc">KYC Bank Details</Link></li>
                          <li><Link to="/logout"><span
                            className="fa fa-sign-out" aria-hidden="true"></span>Logout</Link></li>
                        </ul>
                      </li>
                    }

                    {
                      localStorage.getItem('franchisee-token') !== null &&
                      <li>
                        <label htmlFor="drop-4" className="toggle toogle-2"><span
                          className="fa fa-user-circle-o" aria-hidden="true"></span> {franchisee.franchisee_name} <span className="fa fa-angle-down"
                            aria-hidden="true"></span></label>
                        <Link to="#" className="link-nav dropdown-toggle"> <span
                          className="fa fa-user-circle-o" aria-hidden="true"></span> {franchisee.franchisee_name} <span
                            className="fa fa-angle-down" aria-hidden="true"></span></Link>
                        <input type="checkbox" id="drop-4" />
                        <ul>
                          <li><Link to="/franchisee-dashboard">Dashboard</Link></li>
                          <li><Link to="/franchisee-profile">Profile</Link></li>
                          <li><Link to="/franchisee-images">Shop Images</Link></li>
                          <li><Link to="/franchisee-sales">Sales</Link></li>
                          <li><Link to="/franchisee-transactions">Transactions</Link></li>
                          <li><Link to="/logout"><span
                            className="fa fa-sign-out" aria-hidden="true"></span>Logout</Link></li>
                        </ul>
                      </li>
                    }


                    {
                      localStorage.getItem('login-token') === null && localStorage.getItem('franchisee-token') === null &&
                      <li className="nav-right-sty">
                        <Link to="/register" className="btn button-eff button-eff-2"><span
                          className="fa fa-registered" aria-hidden="true"></span>Register</Link>
                      </li>
                    }

                    {
                      localStorage.getItem('login-token') === null && localStorage.getItem('franchisee-token') === null &&
                      <li className="nav-right-sty">
                        <Link to="/login" className="btn button-eff button-eff-2"><span
                          className="fa fa-sign-in" aria-hidden="true"></span>Login</Link>
                      </li>
                    }

                    {/*<li className="nav-right-sty">
                                        <Link to="post-ad.html" className="btn button-eff button-eff-2"><span
                                                className="fa fa-paper-plane-o" aria-hidden="true"></span>Post your Ad</Link>
                                    </li>*/}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </div>
  )
}