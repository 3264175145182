import React from 'react'
import { Link } from 'react-router-dom';
import Header from '../common/Header.js'
import InnerBanner from '../common/InnerBanner.js'
import Footer from '../common/Footer.js'
export const About = () => {
  return (
    <>
      <Header />
      <InnerBanner title={'About Us'} />
      <section className="w3l-covers-9-main">
        <div className="covers-9 sec-padding">
          <div className="wrapper">
            <div className="d-grid cover-top-center-9">
              <div className="w3ls_cover_txt-9">
                {/* <h6 className="tag-cover-9">Theme on dlmp</h6> */}
                <h3 className="title-cover-9">Welcome To DLMP21</h3>
                <p className="para-cover-9">DLMP21 is a venture of Dreams Linkage Marketing Pro 21(I)(OPC) Pvt Ltd. Which was established in the year 2017 with a mission of providing daily needs & shops advertisement, door to door and via online portal to the common people. We strive hard to make a hassle free shopping experience for the people & give commissions using the MLM stage on their purchasing amount on DLMP21.
                </p>
                {/* <a href="product-single.html" className="btn button-eff">Learn More</a> */}
              </div>
              <div className="right-text">
                <img src="assets/images/about.jpg" alt="" className="img-responsive img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="w3l-features-4">
        <div className="features4-block">
          <div className="wrapper">
            <h3 className="title-main">How it works?</h3>
            <div className="features4-grids text-left">
              <div className="features4-grid">
                <div className="feature-images">
                  <span className="fa fa-users" aria-hidden="true"></span>
                </div>
                <h5><Link to="/register">First Create Your Account</Link></h5>
                <p>Enter your First and Last name etc.. in the appropriate boxes, then click on submit. By creating an account you agree to our Terms of Service and have read our Privacy Policy.</p>

              </div>
              <div className="features4-grid">
                <div className="feature-images">
                  <span className="fa fa-shopping-bag" aria-hidden="true"></span>
                </div>
                <h5><Link to="/shops">Search Nearest Shops</Link></h5>
                <p>You can search for areas of interest like electronics, fashion, food, groceries etc.</p>

              </div>
              <div className="features4-grid active">
                <div className="feature-images active">
                  <span className="fa fa-gift" aria-hidden="true"></span>
                </div>
                <h5><Link to="/shops">Get Your Best Offers & Purchase</Link></h5>
                <p>We’ll look at some of the most popular ways you can use discounts to drive customer loyalty and conversions and, of course, how to most effectively use them. Purchase your item and ask retailer to update your purchase on DLMP21.</p>

              </div>
              <div className="features4-grid">
                <div className="feature-images">
                  <span className="fa fa-usd" aria-hidden="true"></span>
                </div>
                <h5><Link to="/commisions">Check MLM Stage & Get Commisions</Link></h5>
                <p>Check your DLMP21 MLM Stage and purchase minimum 1000 Rs items to get commisions. Commisions will be send to your direct account every first week of month.</p>
              </div>
            </div>
            <div className="middle-button">
              <Link to={'/how-its-works'} className="btn button-eff">View Details</Link>
            </div>
          </div>
        </div>
      </section>

      <div className="w3l-cutomer-main-cont">
        <div className="testimonials text-center sec-padding">
          <div className="wrapper">
            <h3 className="title-main">Customers Say</h3>
            <div className="d-grid content-sec">
              <div className="testi-sections">
                <div className="testimonials_grid">
                  <p className="sub-test"><span className="fa fa-quote-left mr-2" aria-hidden="true"></span> Nam
                    libero
                    tempore, cum soluta
                    nobis est eligendi optio cumque nihil impedit.
                  </p>
                  <div className="d-grid sub-author-con">
                    <div className="testi-img-res">
                      <img src="assets/images/testi1.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="testi_grid text-left">
                      <h5 className="mb-1">Petey Cruis</h5>
                      <p>Caption Here</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testi-sections">
                <div className="testimonials_grid">
                  <p className="sub-test"><span className="fa fa-quote-left mr-2" aria-hidden="true"></span> Nam
                    libero
                    tempore, cum soluta
                    nobis est eligendi optio cumque nihil impedit.
                  </p>
                  <div className="d-grid sub-author-con">
                    <div className="testi-img-res">
                      <img src="assets/images/testi2.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="testi_grid text-left">
                      <h5 className="mb-1">Molive Joe</h5>
                      <p>Caption Here</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="testi-sections">
                <div className="testimonials_grid">
                  <p className="sub-test"><span className="fa fa-quote-left mr-2" aria-hidden="true"></span> Nam
                    libero
                    tempore, cum soluta
                    nobis est eligendi optio cumque nihil impedit.
                  </p>
                  <div className="d-grid sub-author-con">
                    <div className="testi-img-res">
                      <img src="assets/images/testi.jpg" alt="" className="img-responsive" />
                    </div>
                    <div className="testi_grid text-left">
                      <h5 className="mb-1">Paige Turner</h5>
                      <p>Caption Here</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
