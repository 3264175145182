import React, { useState, useEffect } from 'react'
import { useLocation,useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading'
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'

export default function Read() {
  const data = useLocation();
  const navigate = useNavigate();
  const [pageloading, setpageLoading] = useState(false);
  const [news, setNews] = useState(null);
  const [comment, setComment] = useState({});
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchdata = async () => {
      const news = data.state;
      setpageLoading(true);
      await Api.post('news/single', jwt_encode(news, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setNews(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setNews(result.data.data);
            setComment({ ...comment, ["news_id"]: news.id,})
          }
        });
    };
    fetchdata();
  }, []);

  const changeComments = (event) => {
    event.preventDefault();
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setComment({ ...comment, [name]: value, })
  }

  const submitComments = async (event) => {
    setLoading(true);
    event.preventDefault();
    event.persist();
    await Api.post('news/comments', jwt_encode(comment, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        setLoading(false);
        if (status === 'failure') {
          //result.data.errors;
          let data_error = result.data.errors;
          setErrors(data_error);
          //toast.error(data_error.msg, {position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          toast.success(result.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
					setErrors({});
          setComment(result.data.data);
        }
      });
  }

  const moveComments = (news_id) => {
    navigate('/comments',{state:{news_id:comment.news_id}});
  }

  return (
    <>
      <Header />
      <ToastContainer />
      {(pageloading) ? <Loading /> :
        <>
          {news !== null &&
            <section className="w3l-blog-single">
              <div className="single blog">
                <div className="wrapper">
                  <h3 className="title-main">{news.category} News</h3>
                  <div className="main-cont-blog">
                    <div className="single-left">
                      <div className="single-left1">
                        <img src={news.image} alt=" " className="img-responsive img-fluid" />
                        <div className="btom-cont">
                          <h5 className="card-title" style={{ float: 'unset' }} ><a href="#.">{news.title}</a></h5>
                          <ul className="admin-post">
                            <li>
                              <a href="#."><span className="fa fa-user"></span> Admin</a>
                            </li>
                            <li>
                              <a href="#."><span className="fa fa-clock-o"> {news.created_at}</span></a>
                            </li>
                            <li>
                              <a href="#."><span className="fa fa-comments-o"></span>Comments {news.comments}</a>
                            </li>
                          </ul>
                          <p dangerouslySetInnerHTML={{ __html: news.news }}></p>
                        </div>
                      </div>
                      {news.quata &&
                        <blockquote>
                          <p className="quote-text">
                            <span className="fa fa-quote-left" aria-hidden="true"></span>
                            {news.quata.toString().replace(/(<([^>]+)>)/ig, '').trim()}
                            <span className="fa fa-quote-right" aria-hidden="true"></span>
                          </p>
                          {/* <p><a href="#author">- Alfred Marshel</a></p> */}
                        </blockquote>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }
          {news !== null && news.comments !== 0 &&
            <section className="w3l-comments-9-main">
              <div className="gallery-32">
                <div className="wrapper">
                  <div className="grids-32-content">
                    <h3 className="title-main2">Comments ({news.comments})</h3>
                    <div className="d-grid grid-columns">
                      {news.comment_data.map((val, key) =>
                        <div className="box13" key={key}>
                          <div className="testi-w3pvt">
                            <div className="testi-agile">
                              <p>
                                {val.comment}
                              </p>
                            </div>
                            <div className="testi-pos d-flex">
                              {/* <img src="assets/images/testi2.jpg" alt="" className="img-responsive rounded-circle mb-3" /> */}
                              <div className="right-coment">
                                <h4>{val.name}</h4>
                                <span>{val.created_at}</span>
                              </div>
                            </div>
                          </div>
                        </div>
                      )
                      }
                    </div>
                    {news.comments != 0 && <p className="quote-text mt-10" style={{ textAlign: 'center' }}><button onClick={(e)=>{e.preventDefault(); e.persist();moveComments(data.id)}} className="btn button-eff" type="submit">View All Comments</button></p>}
                  </div>
                </div>
              </div>
            </section>
          }
          {news != null &&
            <section className="w3l-comments-form-9">
              <div className="coments-forms-sub">
                <div className="wrapper">
                  <div className="testi-top">
                    <h3 className="title-main2">Leave A Comment</h3>
                    <div className="form-commets">
                      <form onSubmit={submitComments}>
                        <div className="media">
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="text" name="name" onChange={changeComments} value={comment.name} placeholder="Your Name" style={{marginBottom:'unset'}}/>
                              {errors.name && <span id="exampleInputEmail1-error" className="error invalid-feedback" style={{display:"block",color:"red"}}>{errors.name}</span>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <input type="email" name="email" onChange={changeComments} value={comment.email} placeholder="Your Email" style={{marginBottom:'unset'}} />
                              {errors.email && <span id="exampleInputEmail1-error" className="error invalid-feedback" style={{display:"block",color:"red"}}>{errors.email}</span>}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group">
                              <textarea name="comment" onChange={changeComments} value={comment.comment} placeholder="Write your comments here" style={{marginBottom:'unset'}} />
                              {errors.comment && <span id="exampleInputEmail1-error" className="error invalid-feedback" style={{display:"block",color:"red"}}>{errors.comment}</span>}
                            </div>
                          </div>
                          <button type="submit" className="btn button-eff">
                            <span style={{marginRight:"0px"}}>Submit</span><span style={{float:"right",width:"10px",height:"10px"}}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          }

        </>
      }
      <Footer />
    </>
  )
}
