import React, { useState, useEffect } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import CustomerAuth from '../common/CustomerAuth';
import Footer from '../common/Footer';
import Header from '../common/Header';
const Dashboard = () => {
  const [pageloading, setpageLoading] = useState(false);
  const [goal, setGoal] = useState({});
  const [counts, setCounts] = useState({});
  const [estimate, setEstimate] = useState({});
  const [purchase, setPurchase] = useState(null);
  const [commision, setCommision] = useState(null);

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('login-token'));
    const fetchdata = async () => {
      setpageLoading(true);
      await Api.post('customers/dashboard', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            let dashboard = result.data.data;
            //console.log(dashboard);
            setpageLoading(false);
            setGoal(dashboard.goal);
            setCounts(dashboard.counts);
            setEstimate(dashboard.estimate);
            setPurchase(dashboard.purchase);
            setCommision(dashboard.commision);
            if (dashboard.goal.completed < 5)
              toast.error(dashboard.goal.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          }
        });
    };
    fetchdata();
  }, []);

  return (
    <>
      <CustomerAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-blog-single">
        <div className="single blog">
          <div className="wrapper">
            {(pageloading) ? <Loading /> :
              <>
                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-center">
                              <strong>DLMP21 Customer Code : <span className='text-danger'>{goal.code}</span></strong>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {goal.status === "failure" &&
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-md-12">
                              <p className="text-center">
                                <strong>Profile Completion</strong>
                              </p>

                              <div className="progress-group">
                                Profile Status
                                <span className="float-right"><b>{goal.completed}</b>/ 5</span>
                                <div className="progress progress-sm">
                                  <div className="progress-bar bg-primary" style={{ width: "80%" }}></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                }
                <div className="row">
                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box">
                      <span className="info-box-icon bg-info elevation-1"><i className="fa fa-shopping-bag"></i></span>

                      <div className="info-box-content">
                        <span className="info-box-text">Shop Visited</span>
                        <span className="info-box-number">{counts.visitors}</span>
                      </div>

                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box mb-3">
                      <span className="info-box-icon bg-success elevation-1"><i className="fa fa-shopping-cart"></i></span>

                      <div className="info-box-content">
                        <span className="info-box-text">Total Purchase</span>
                        <span className="info-box-number">{counts.purchase}</span>
                      </div>
                    </div>

                  </div>

                  <div className="clearfix hidden-md-up"></div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <div className="info-box mb-3">
                      <span className="info-box-icon bg-danger elevation-1"><i className="fa fa-thumbs-up"></i></span>
                      <div className="info-box-content">
                        <span className="info-box-text">Total Commission</span>
                        <span className="info-box-number">{counts.commision}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-12 col-sm-6 col-md-3">
                    <Link to={'/downline'}>
                      <div className="info-box mb-3">
                        <span className="info-box-icon bg-warning elevation-1"><i className="fa fa-users"></i></span>
                        <div className="info-box-content">
                          <span className="info-box-text">Downline Members</span>
                          <span className="info-box-number">{counts.downline}</span>
                        </div>
                      </div>
                    </Link>
                  </div>

                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="text-center">
                              <strong>{estimate.date}</strong>
                            </p>
                            <p className="text-center">
                              <strong>Goal Completion {(estimate.purchase > 1000) && <i className="fa fa-check text-success"></i>}</strong>
                            </p>

                            <div className="progress-group">
                              Purchase From Nearest Shops
                              <span className="float-right"><b>Rs. {estimate.purchase}</b>/ Rs. 1000</span>
                              <div className="progress progress-sm">
                                <div className="progress-bar bg-primary" style={{ width: estimate.goal + "%" }}></div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <div className="row">
                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className={`description-percentage text-${estimate.visit_rate && estimate.visit_rate.split('-', 3)[2]}`}><i className={`fa fa-caret-${estimate.visit_rate && estimate.visit_rate.split('-', 3)[1]}`}></i> {estimate.visit_rate && estimate.visit_rate.split("-", 3)[0]}%</span>
                              <h5 className="description-header">{estimate.vistor}</h5>
                              <span className="description-text">Shop Visited</span>
                            </div>

                          </div>

                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className={`description-percentage text-${estimate.set_purchase && estimate.set_purchase.split('-', 2)[1]}`}><i className={`fa fa-caret-${estimate.set_purchase && estimate.set_purchase.split('-', 2)[0]}`}></i> {(estimate.goal < 100) ? estimate.goal : '100'}%</span>
                              <h5 className="description-header">Rs. {estimate.purchase}</h5>
                              <span className="description-text">Purchase</span>
                            </div>

                          </div>

                          <div className="col-sm-3 col-6">
                            <div className="description-block border-right">
                              <span className={`description-percentage text-${estimate.set_commision && estimate.set_commision.split('-', 3)[2]}`}><i className={`fa fa-caret-${estimate.set_commision && estimate.set_commision.split('-', 3)[1]}`}></i> {estimate.set_commision && estimate.set_commision.split("-", 3)[0]}%</span>
                              <h5 className="description-header">Rs.{estimate.commision}</h5>
                              <span className="description-text">Commission</span>
                            </div>

                          </div>

                          <div className="col-sm-3 col-6">
                            <div className="description-block">
                              <span className={`description-percentage text-${estimate.set_members && estimate.set_members.split('-', 3)[2]}`}><i className={`fa fa-caret-${estimate.set_members && estimate.set_members.split('-', 3)[1]}`}></i> {estimate.set_members && estimate.set_members.split("-", 3)[0]}%</span>
                              <h5 className="description-header">{estimate.members}</h5>
                              <span className="description-text">Members</span>
                            </div>
                          </div>
                        </div>

                      </div>

                    </div>

                  </div>

                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Latest Purchase</h3>

                        <div className="card-tools">
                          <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Franchisee/Shop</th>
                                <th>Items</th>
                                <th>Amount</th>
                              </tr>
                            </thead>
                            {(purchase !== null) ?
                              <tbody>
                                {
                                  purchase.map((purchase, purchaseIndex) =>
                                    <tr key={purchaseIndex}>
                                      <td><strong>{purchase.created_at}</strong></td>
                                      <td><span className='text-success'>{purchase.franchisee_name}</span></td>
                                      <td><span className=''>{purchase.items.slice(0, 15)}...</span></td>
                                      <td><span className="text-success">{purchase.amount}</span></td>
                                    </tr>
                                  )
                                }
                              </tbody>
                              :
                              <tbody>
                                <tr>
                                  <td colSpan={3}>Purchase not found !!!</td>
                                </tr>
                              </tbody>
                            }
                          </table>
                        </div>
                      </div>
                      {purchase !== null &&
                        <div className="card-footer clearfix">
                          <Link to="/purchase" className="btn btn-sm btn-secondary float-right">View All Purchase</Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-12">
                    <div className="card">
                      <div className="card-header border-transparent">
                        <h3 className="card-title">Latest Commissions</h3>

                        <div className="card-tools">
                          <button type="button" className="btn btn-tool" data-card-widget="collapse">
                            <i className="fa fa-minus"></i>
                          </button>
                        </div>
                      </div>
                      <div className="card-body p-0">
                        <div className="table-responsive">
                          <table className="table m-0">
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Purchase Amount</th>
                                <th>Commission</th>
                                <th>Status</th>
                              </tr>
                            </thead>
                            {(commision !== null) ?
                              <tbody>
                                {
                                  commision.map((commision, commisionIndex) =>
                                    <tr key={commisionIndex}>
                                      <td><strong>{commision.month}</strong></td>
                                      <td><span className='text-success'>{commision.amount}</span></td>
                                      <td><span className='text-info'>{commision.commision}</span></td>
                                      <td><span className="text-success">{commision.status}</span></td>
                                    </tr>
                                  )
                                }
                              </tbody>
                              :
                              <tbody>
                                <tr>
                                  <td colSpan={3}>Commission not found !!!</td>
                                </tr>
                              </tbody>
                            }
                          </table>
                        </div>
                      </div>
                      {commision !== null &&
                        <div className="card-footer clearfix">
                          <Link to="/commisions" className="btn btn-sm btn-secondary float-right">View All Commission</Link>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </>
            }
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}

export default Dashboard