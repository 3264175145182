import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import CustomerAuth from '../common/CustomerAuth';
//import { omit } from 'lodash'

function Kyc() {
  const [pageloading, setpageLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({});
  const [errors, setErrors] = useState({});

  useEffect(() => {
    const customer = JSON.parse(localStorage.getItem('login-token'));
    setpageLoading(true);
    const fetchdata = async () => {
      await Api.post('customers/profile', jwt_encode(customer, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            //console.log(result.data.data);
            setProfile(result.data.data);
          }
        });
    };
    fetchdata();
  }, []);

  const handleChange = (event) => {
    //To stop default events    
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    //changeValidate(event, name, value);
    //Let's set these values in state
    setProfile({ ...profile, [name]: value, })
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    //submitValidate(profile, errors);
    setLoading(true);
    await Api.post('customers/kyc', jwt_encode(profile, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        setLoading(false);
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          setErrors(result.data.errors);
        } else if (status === 'success') {
          setErrors({});
          toast.success('your kyc bank details updated successfully !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        }
      });
  }

  return (
    <>
      <CustomerAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">KYC Bank Details</h3>
                    <br />
                    <p style={{ fontSize: "14px", color: '#ffffff' }}>Please fill your KYC Bank Details to get your commisions direct to your bank account.</p>
                  </div>
                  {(pageloading) ? <Loading /> :
                    <form onSubmit={handleSubmit}>
                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputEmail1">Bank Name</label>
                              <input type="text" name="bank_name" value={profile.bank_name} onChange={handleChange} className={(errors.bank_name) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Bank Name" />
                              {errors.bank_name && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.bank_name}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Branch</label>
                              <input type="text" name="branch" value={profile.branch} onChange={handleChange} className={(errors.branch) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Branch" />
                              {errors.branch && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.branch}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">IFSC Code</label>
                              <input type="text" name="ifsc" value={profile.ifsc} onChange={handleChange} className={(errors.ifsc) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter IFSC Code" />
                              {errors.ifsc && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.ifsc}</span>}
                            </div>
                          </div>

                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="exampleInputPassword1">Account No.</label>
                              <input type="text" name="account_no" value={profile.account_no} onChange={handleChange} className={(errors.account_no) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter Account No." />
                              {errors.account_no && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.account_no}</span>}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-footer">
                        <button type="submit" className="btn button-eff">
                          <span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px" ,marginLeft:"5px"}}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                        </button>
                      </div>
                    </form>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}

export default Kyc