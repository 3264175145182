import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import FranchiseeAuth from '../common/FranchiseeAuth';
export default function AddSales() {
  const [loading, setLoading] = useState(false);
  const [sales, setSales] = useState({ cust_id: "", amount: "", items: "" });
  const [errors, setErrors] = useState({});
  const [verify, setVerify] = useState({});
  const [verifyLoader, setverifyLoader] = useState(false);
  const handleChange = (event) => {
    //To stop default events    
    event.persist();
    let name = event.target.name;
    let value = event.target.value;
    setSales({ ...sales, [name]: value, })
  }

  const verifyCustomer = async () => {
    if (sales.cust_id.length !== 0) {
      setverifyLoader(true);
      await Api.post('franchisee/checkCustomer', jwt_encode(sales, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          setverifyLoader(false);
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            setVerify({});
            setErrors(result.data.errors);
          } else if (status === 'success') {
            setErrors({});
            setVerify(result.data.data);
          }
        });
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.persist();
    if (submitValidate()) {
      const franchisee = JSON.parse(localStorage.getItem('franchisee-token'));
      const data = { franchisee: franchisee, sales: sales }
      setLoading(true);
      await Api.post('franchisee/addSales', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          setLoading(false);
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            if (result.data.errors.msg) {
              toast.error(result.data.errors.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
            } else {
              setErrors(result.data.errors);
            }
          } else if (status === 'success') {
            setErrors({});
            setVerify({});
            setSales({ cust_id: "", amount: "", items: "" });
            toast.success(result.data.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          }
        });
    }
  }

  const submitValidate = () => {
    let err = {};
    if (sales.cust_id.length === 0) {
      err.cust_id = "Please enter customer code.";
    }
    if (sales.amount.length === 0) {
      err.amount = "Please enter amount.";
    }
    if (sales.items.length === 0) {
      err.items = "Please enter items.";
    }
    setErrors(err);
    if (Object.keys(err).length === 0)
      return true;
    return false;
  }

  return (
    <>
      <FranchiseeAuth />
      <Header />
      <ToastContainer />
      <section className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">Add Sales</h3>
                  </div>
                  <form onSubmit={handleSubmit}>
                    <div className="card-body">
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputEmail1">Customer Code</label>
                            <div className="input-group mb-3">
                              <div className="input-group-prepend">
                                <span className="input-group-text">DLMP</span>
                              </div>
                              <input type="text" name="cust_id" value={sales.cust_id} onChange={handleChange} className={(errors.cust_id) ? 'form-control is-invalid' : (verify.cust_id) ? "form-control is-valid" : 'form-control'} placeholder="Enter customer code" autoComplete='off' />
                              <div className="input-group-append">
                                <span className="input-group-text" style={{ cursor: "pointer" }} onClick={verifyCustomer}>Verify {verifyLoader && <i className="fa fa-circle-o-notch fa-spin fa-fw"></i>} </span>
                              </div>
                              {errors.cust_id && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.cust_id}</span>}
                              {verify.cust_id && <span className="success valid-feedback">{"Customer name : " + verify.f_name + " " + verify.l_name}</span>}
                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Amount</label>
                            <input type="text" name="amount" value={sales.amount} onChange={handleChange} className={(errors.amount) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter amount" autoComplete='off' />
                            {errors.amount && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.amount}</span>}
                          </div>
                        </div>

                        <div className="col-md-12">
                          <div className="form-group">
                            <label htmlFor="exampleInputPassword1">Items</label>
                            <textarea type="text" name="items" value={sales.items} onChange={handleChange} className={(errors.items) ? 'form-control is-invalid' : 'form-control'} placeholder="Enter items in shorts" autoComplete='off' />
                            {errors.items && <span id="exampleInputEmail1-error" className="error invalid-feedback">{errors.items}</span>}
                          </div>
                        </div>

                      </div>
                    </div>

                    <div className="card-footer">
                      <button type="submit" className="btn button-eff">
                        <span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      <Footer />
    </>
  )
}
