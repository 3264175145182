import React, {useState, createRef} from 'react'
import './tailwind.min.css'
import './verifications.css'
import { Link, useNavigate } from 'react-router-dom';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";

function ForgotVerify() {
  const navigate = useNavigate();
  if (localStorage.getItem('login-token') !== null)
    navigate('/dashboard');
  if (localStorage.getItem('reset-token') !== null)
    navigate('/reset-password');
  if (localStorage.getItem('forgot-token') === null)
    navigate('/forgot-password');

	const [value,setJSON] = useState(JSON.parse(localStorage.getItem('forgot-token')));
	const [values, setValues] = useState({ first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' });
	const [inputRefsArray] = useState(() => Array.from({ length: 6 }, () => createRef()));
  const [res_errors, setRes_errors] = useState([]);
  const [loading,setLoading ]= useState(false);
	const [resend,resResend] = useState();

	const handleChange = (event) => {
		let index = Number(event.target.id);
		setValues({ ...values, [event.target.name]: event.target.value, });
		let nextIndex = index < 6 - 1 ? index + 1 : 0;
		let nextInput = inputRefsArray?.[nextIndex]?.current;
		let currentInput = inputRefsArray?.[index]?.current;
		let prevInput = inputRefsArray?.[index - 1]?.current;
		if (event.key === 'Backspace') {
			currentInput.value = '';
			if (index !== 0)
				prevInput.focus();
			setValues({ ...values, [currentInput.name]: currentInput.value, });
			event.preventDefault();
		} else if (event.keyCode > 47 && event.keyCode < 58) {
			currentInput.value = event.key;
			if (index !== 5)
				nextInput.focus();
			setValues({ ...values, [currentInput.name]: currentInput.value, });
			currentInput.style='';
			event.preventDefault();
		} else if (event.keyCode > 64 && event.keyCode < 91) {
        currentInput.value = '';
				setValues({ ...values, [currentInput.name]: currentInput.value, });
				event.preventDefault();
		}

	}

	const handleSubmit = async (event) => {
		if (event) event.preventDefault();
		if (validate()) {
			setLoading(true);
			let send = {id:value.id,mobile:value.mobile,otp:values};
      await Api.post('/forgot/check',jwt_encode(send,process.env.REACT_APP_SECRET_KEY))
			.then(res => {
				const result = jwt_decode(res.data);
				const status = result.data.status;
				setLoading(false);
				if (status==='failure') {
					let server_errors = result.data.errors;
					setRes_errors(server_errors)
					setTimeout(() => {setRes_errors([]);}, 8000);
				} else if(status==='success') {
					localStorage.setItem('reset-token',JSON.stringify(result.data.data));
					localStorage.removeItem('forgot-token');
					navigate('/reset-password');
				}
			})
		}
	}

	const handleResend = async (event) => {
    let send = {id:value.id,mobile:value.mobile,otp:values};
		await Api.post('/forgot/resend',jwt_encode(send,process.env.REACT_APP_SECRET_KEY))
		.then(res => {
			const result = jwt_decode(res.data);
			const status = result.data.status;
			if (status==='failure') {
				// result.data.errors;
				let server_errors = result.data.errors;
				setRes_errors(server_errors)
				setTimeout(() => {
				  setRes_errors([]);	
				}, 8000);
			} else if(status==='success') {
				setValues({ first: '', second: '', third: '', fourth: '', fifth: '', sixth: '' });
				localStorage.setItem('forgot-token',JSON.stringify(result.data.data));
				setJSON(result.data.data);
				resResend(result.data.message);
				setTimeout(() => {
				  resResend(null);	
				}, 5000);
			}
		})
	}

	const validate = () => {
		let invalid = false;
    if (values.first.trim().length === 0) {
			let firstInput = inputRefsArray?.[0]?.current
			firstInput.style = "border: 1px solid red";
			invalid = true;
		}
		if (values.second.trim().length === 0) {
		  let secondInput = inputRefsArray?.[1]?.current
			secondInput.style = "border: 1px solid red";
			invalid = true;
		}
		if (values.third.trim().length === 0) {
		  let thirdInput = inputRefsArray?.[2]?.current
			thirdInput.style = "border: 1px solid red";
			invalid = true;
		}
		if (values.fourth.trim().length === 0) {
		  let fourthInput = inputRefsArray?.[3]?.current
			fourthInput.style = "border: 1px solid red";
			invalid = true;
		}
		if (values.fifth.trim().length === 0) {
		  let fifthInput = inputRefsArray?.[4]?.current
			fifthInput.style = "border: 1px solid red";
			invalid = true;
		}
		if (values.sixth.trim().length === 0) {
		  let sixthInput = inputRefsArray?.[5]?.current
			sixthInput.style = "border: 1px solid red";
			invalid = true;
		}

		if (invalid)
		  return false;
		return true;
	}

	return (
		<section className="w3l-forms-23">
			<div className="forms23-block">
				<div className="wrapper">
					<h1>
						<Link to="#." className="logo-2" style={{ color: "#ffffff", fontWeight: "700" }}>DLMP21</Link>
					</h1>
					<div className="d-grid forms23-grids">
						<div className="form23">
							<h6 className='text-center'>Verifications</h6>
							<p className='text-center'>Enter the OTP code send on your mobile <strong>{value.mobile}</strong> no below to verify.</p>
							<p className='text-center'><small className="text-info">* otp valid till <strong>{value.valid}</strong></small></p>
							<br />
							<form onSubmit={handleSubmit}>
								<div className="mb-6 text-center">
									<div id="otp" className="flex justify-center">
										{
											inputRefsArray.map((ref, index) => {
												let name = '';
												let myvalue = '';
												let autoFocus = false;
												if (index === 0){
													name = 'first'
													myvalue = values.first
												}
												if (index === 0)
												  autoFocus=true;
												if (index === 1){
													name = 'second'
													myvalue = values.second
												}
												if (index === 2){
													name = 'third'
													myvalue = values.third
												}
												if (index === 3){
													name = 'fourth'
													myvalue = values.fourth
												}
												if (index === 4){
													name = 'fifth'
													myvalue = values.fifth
												}
												if (index === 5){
													name = 'sixth'
													myvalue = values.sixth
												}
												return (
													<input className={`m-2 text-center form-control form-control-solid rounded focus:border-blue-400 focus:shadow-outline`}
														key={index}
														type="tel"
														pattern="[0-9]{1}"
														id={index}
														name={name}
														value={myvalue}
														maxLength="1"
														ref={ref}
														autoFocus = {autoFocus}
														keyboardtype="numeric"
														onKeyDown={handleChange}
														onChange={handleChange}
														style={{padding:"unset"}}
													/>
												)
											})
										}
									</div>
									{res_errors.msg && <small className="text-danger">{res_errors.msg}</small>}
									{resend && <small className="text-success">{resend}</small>}
								</div>
								<button type="submit" className="btn button-eff" style={{ padding: 'unset', height: '40px' }}>
									<span style={{ marginLeft: "35px" }}>Submit</span><span style={{ float: "right", width: "10px", height: "10px", marginLeft: "5px" }}>{loading && <i className="fa fa-circle-o-notch fa-spin"></i>}</span>
								</button>
								<p className='text-center'><small>* otp not received.. <Link onClick={handleResend} style={{color:'green'}} to={'#'}>Resend OTP now !!!</Link></small></p>
							</form>

						</div>
						<div className="form23-text">
							<h3>Buy, Sell, Find, Shop Ads, Rent & Exchange in one Click</h3>
							<p>Our jobs as marketers are to understand how the customer wants to buy and help them to do so.
							</p>
							<Link to="/" className="btn button-eff button-eff-2"><span className="fa fa-hand-o-left"
								aria-hidden="true"></span>
								Back to Home</Link>
						</div>
					</div>
				</div>
			</div>
		</section>
	)
}

export default ForgotVerify