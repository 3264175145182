import React from 'react'
import Header from '../common/Header.js'
import InnerBanner from '../common/InnerBanner.js'
import Footer from '../common/Footer.js'
export const Faq = () => {
  return (
    <>
      <Header />
      <InnerBanner title={'Faq'} />
      <div className="w3l-faq-main">
        <div className="faq-sec sec-padding">
          <div className="wrapper">
            <div className="faq-cont">
              <div className="about-right-faq">
                <div className="bg-warning text-dark"><b>Warning:</b> Please, look at the answers before
                  contacting.</div>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Phasellus hendrerit sodales arcu
                  quis
                  vulputate. Nulla ullamcorper risus sit amet condimentum accumsan. Mauris tempus enim eu
                  lorem
                  eleifend blandit.</p>
                <p className="mt-3">Suspendisse porta erat sit amet eros sagittis, quis hendrerit
                  libero aliquam. Fusce semper augue ac dolor efficitur.</p>

                <div className="sub-accor">
                  <h3 className="title-w3-ab text-uppercase mt-5 mb-4">Frequently asked questions</h3>
                  <ul className="accordion css-accordion">
                    <li className="accordion-item">
                      <input className="accordion-item-input" type="checkbox" name="accordion" id="item2"/>
                      <label htmlFor="item2" className="accordion-item-hd">Lorem ipsum dolor sit amet? <span
                        className="accordion-item-hd-cta">&#9650;</span></label>
                      <div className="accordion-item-bd accordion-item-bd-2">
                        <h6 className="accordion-textm">Dolores et ea rebum lorem ipsum</h6>
                        <p>Duo dolores et ea rebum. Lorem ipsum dolor sit ametLorem ipsum
                          dolor sit amet,sed diam nonumy. Consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <input className="accordion-item-input" type="checkbox" name="accordion" id="item1" />
                      <label htmlFor="item1" className="accordion-item-hd">At vero eos et accusamus et iusto odio
                        dignissimos ducimus? <span className="accordion-item-hd-cta">&#9650;</span></label>
                      <div className="accordion-item-bd">
                        <h6 className="accordion-textm">Nemo enim ipsam volup tatem</h6>
                        <p>Sodales quis.At vero eos et accusam et justo duo dolores et ea rebum. Lorem
                          ipsum
                          dolor sit ametLorem ipsum dolor sit amet,sed diam nonumy. Consectetur
                          adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <input className="accordion-item-input" type="checkbox" name="accordion" id="item5" />
                      <label htmlFor="item5" className="accordion-item-hd">Vitae diam rutrum condimentum? <span
                        className="accordion-item-hd-cta">&#9650;</span></label>
                      <div className="accordion-item-bd">
                        <h6 className="accordion-textm">Totam rem aperiam, eaque ipsa quae</h6>
                        <p>Duo dolores et ea rebum. Lorem ipsum dolor sit ametLorem ipsum
                          dolor sit amet,sed diam nonumy. Consectetur adipiscing elit, sed do eiusmod
                          tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <input className="accordion-item-input" type="checkbox" name="accordion" id="item3" />
                      <label htmlFor="item3" className="accordion-item-hd">Quos dolores et quas
                        molestias excepturi sint?
                        <span className="accordion-item-hd-cta">&#9650;</span></label>
                      <div className="accordion-item-bd">
                        <h6 className="accordion-textm">Sunt in culpa qui officia deserunt</h6>
                        <p>sodales quis. At vero eos et accusam et justo duo dolores et ea rebum. Lorem
                          ipsum dolor sit ametLorem ipsum dolor sit amet,sed diam nonumy. Consectetur
                          adipiscing elit, sed do eiusmod tempor incididunt ut labore et
                          dolore magna aliqua.</p>
                      </div>
                    </li>
                    <li className="accordion-item">
                      <input className="accordion-item-input" type="checkbox" name="accordion" id="item4" />
                      <label htmlFor="item4" className="accordion-item-hd">Dolores et quas molestias excepturi
                        sint?<span className="accordion-item-hd-cta">&#9650;</span></label>
                      <div className="accordion-item-bd">
                        <h6 className="accordion-textm">Ut enim ad minim veniam, quis</h6>
                        <p>Sodales quis at vero eos et accusam et justo duo dolores et ea rebum. Lorem
                          ipsum
                          dolor sit ametLorem ipsum dolor sit amet,sed diam nonumy. Consectetur
                          adipiscing
                          elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}
