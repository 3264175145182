import React, { Component } from 'react'
import loading from '../loading.gif'
export default class Loading extends Component {
  render() {
    return (
      <div className='text-center' style={{width:'100%'}}>
        <center>
          <img style={{width:'100px'}} src={loading} alt="loading.." />
        </center>
      </div>
    )
  }
}
