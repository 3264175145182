import { useState } from 'react'
import { omit } from 'lodash'
const Validations = (fields, callback) => {
	//Form values
	//const [values, setValues] = useState({});
	const [values, setValues] = useState(fields);
	//Errors
	const [errors, setErrors] = useState({});
	//A method to handle form inputs
	const handleChange = (event) => {
		//To stop default events    
		event.persist();
		let name = event.target.name;
		let value = event.target.value;
		changeValidate(event, name, value);
		//Let's set these values in state
		setValues({ ...values, [name]: value, })
	}

	const changeValidate = (event, name, value) => {
		//A function to validate each input values
		switch (name) {
			case 'firstName':
				if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
					// we will set the error state
					setErrors({ ...errors, firstName: 'you can not use numbers,symbols & spaces in first name.' })
				} else {
					// set the error state empty or remove the error for username input
					//omit function removes/omits the value from given object and returns a new object
					let newObj = omit(errors, "firstName");
					setErrors(newObj);
				}
				break;

			case 'middleName':
				if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
					setErrors({ ...errors, middleName: 'you can not use numbers,symbols & spaces in middle name.' })
				} else {
					let newObj = omit(errors, "middleName");
					setErrors(newObj);
				}
				break;

			case 'lastName':
				if (!value.match(/^[a-zA-Z]+$/) && value !== '') {
					setErrors({ ...errors, lastName: 'you can not use numbers,symbols & spaces in last name.' })
				} else {
					let newObj = omit(errors, "lastName");
					setErrors(newObj);
				}
				break;

			case 'mobile':
				if (!/^[0-9]+$/.test(value) && value !== '') {
					setErrors({ ...errors, mobile: 'mobile no should be numbers.' })
				} else {
					let newObj = omit(errors, "mobile");
					setErrors(newObj);
				}
				break;
			default:
				break;
		}
	}

	const submitValidate = (values) => {
		const subErros = {};
		if (values.firstName !== undefined) {
			if (values.firstName.trim().length === 0) {
				subErros.firstName = 'please enter the first name.';
			} else if (!values.firstName.match(/^[a-zA-Z]+$/)) {
				subErros.firstName = 'you can not use numbers,symbols & spaces in first name.';
			}
		}

		if (values.middleName !== undefined) {
			if (values.middleName.trim().length === 0) {
				subErros.middleName = 'please enter the middle name.';
			} else if (!values.middleName.match(/^[a-zA-Z]+$/)) {
				subErros.middleName = 'you can not use numbers,symbols & spaces in middle name.';
			}
		}

		if (values.lastName !== undefined) {
			if (values.lastName.trim().length === 0) {
				subErros.lastName = 'please enter the last name.';
			} else if (!values.lastName.match(/^[a-zA-Z]+$/)) {
				subErros.lastName = 'you can not use numbers,symbols & spaces in last name.';
			}
		}

		if (values.mobile !== undefined) {
			if (values.mobile.trim().length === 0) {
				subErros.mobile = 'please enter the mobile no.';
			} else if (!/^[0-9]+$/.test(values.mobile)) {
				subErros.mobile = 'mobile no should be numbers.';
			} else if (values.mobile.trim().length !== 10) {
				subErros.mobile = 'mobile no should be exact 10 digits.';
			}
		}

		if (values.email !== undefined) {
			if (values.email.trim().length !== 0 && !values.email.toLowerCase().match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
				subErros.email = 'please enter valid email id.';
			}
		}

		if (values.password !== undefined) {
			if (values.password.trim().length === 0) {
				subErros.password = 'please enter the password.';
			} else if (values.password.trim().length < 8) {
				subErros.password = 'password should be atleast 8 characters.';
			}
		}

		if (values.confirm_password !== undefined) {
			if (values.confirm_password.trim().length === 0) {
				subErros.confirm_password = 'please enter the confirm password.';
			} else if (values.password.trim().length < 8) {
				subErros.confirm_password = 'confirm password should be atleast 8 characters.';
			}
		}

		setErrors(subErros);
		return subErros;
	}

	const handleSubmit = async (event) => {
		if (event) event.preventDefault();
		let res = await submitValidate(values);
		if (Object.keys(errors).length === 0 && Object.keys(res).length === 0 && Object.keys(values).length !== 0) {
			callback();
		}
	}

	return { values, errors, handleChange, handleSubmit }

}

export default Validations
