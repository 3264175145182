import React, { useState, useEffect } from 'react'
import { useNavigate, useLocation } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import default_image from '../default-single.png';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import MapContainer from './MapContainer';


export default function Single() {
  const data = useLocation();
  const navigate = useNavigate();
  const [pageloading, setpageLoading] = useState(false);
  const [shops, setShops] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      const customer = JSON.parse(localStorage.getItem('login-token'));
      let state_id = (data.state) ? data.state.id : 0;
      let shop = {};
      if (customer !== null && customer != undefined) {
        let customer_id = (customer.id) ? customer.id : 0;
        shop = { id: state_id, customer: customer_id }
      } else {
        shop = { id: data.state.id, customer: 0 }
      }

      setpageLoading(true);
      await Api.post('shops/single', jwt_encode(shop, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setShops(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setShops(result.data.data);
            setTimeout(() => {
              window.carousel();
            }, 1000);
          }
        });
    };
    fetchdata();
  }, []);

  const addFavorite = async (event, shop_id) => {
    if (localStorage.getItem('login-token') !== null) {
      const customer = JSON.parse(localStorage.getItem('login-token'));
      let data = { customer_id: customer.id, franchisee_id: shop_id };
      await Api.post('customers/favorites', jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            toast.error(result.data.error, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            if (result.data.action === "store")
              event.target.className = "fa fa-heart";
            if (result.data.action === "remove")
              event.target.className = "fa fa-heart-o";
            toast.success(result.data.data.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          }
        });
    } else {
      toast.error('Please login to add favorite !!!', { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }
  }

  const moveFraud = () => {
    if (localStorage.getItem('login-token') !== null) {
      navigate('/report-fraud');
    } else {
      toast.error("Please login to report against fraud.", { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
    }
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <section className="w3l-products-page w3l-blog-single w3l-products-4">
        {(pageloading) ? <Loading /> :
          shops !== null &&
          <div className="single blog">
            <div className="wrapper">
              <h3 className="title-main">{shops.name}</h3>
              <div className="grid-colunm-2 d-flex">

                <div className="tab-content text-left single-left-content left-product-sing">
                  <h3 className="aside-title single-prt">{shops.city} - {shops.pincode}</h3>
                  <p className="para-single">{shops.address}</p>

                  <div id="slider" className="section-gap">
                    <div className="owl-carousel owl-theme">
                      <div className="item">
                        <div className="blog-grid">
                          <a href="#."><img src={(shops.image1) ? shops.image1 : default_image} alt="" style={{ maxWidth: "100%", objectFit: "revert" }} /></a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="blog-grid">
                          <a href="#."><img src={(shops.image2) ? shops.image2 : default_image} alt="" style={{ maxWidth: "100%", objectFit: "revert" }} /></a>
                        </div>
                      </div>
                      <div className="item">
                        <div className="blog-grid">
                          <a href="#."><img src={(shops.image3) ? shops.image3 : default_image} alt="" style={{ maxWidth: "100%", objectFit: "revert" }} /></a>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="top-sing-sec">
                    {shops.details && <h3 className="aside-title">Shop Details</h3>}
                    <div dangerouslySetInnerHTML={{ __html: shops.details }}></div>

                    {shops.features && <h3 className="aside-title top-sec-space">Features</h3>}

                    <div dangerouslySetInnerHTML={{ __html: shops.features }}></div>

                    <div className="map-single product-details-top">
                      <div className="d-grid some-text-spe">
                        <div className="left-cont">
                          <h4><span className="dlmp-agileinfo">Category </span> : <p> {shops.category},
                            For
                            sale</p>
                          </h4>
                          <h4><span className="dlmp-agileinfo">Location </span> : <p>{shops.city}</p>
                          </h4>
                          <h4><span className="dlmp-agileinfo">Condition </span> : <p>New</p>
                          </h4>
                          <h4><span className="dlmp-agileinfo">Brand </span> : <p>{(shops.brands) ? shops.brands : '-'}
                          </p>
                          </h4>
                          <h4><span className="dlmp-agileinfo">Views </span> : <p><strong> {shops.visitors}</strong>
                          </p>
                          </h4>
                        </div>
                        <div className="right-cont-single">
                          <h3 className="aside-title">Safety Tips for Buyers</h3>
                          <ul>
                            <li>Ask retailer for DLMP21 authorised shop.</li>
                            <li>Confirm purchase amount from retailer.</li>
                            <li>Check your DLMP21 Code from retailer.</li>
                            <li>Ask retailer to add amount of purchase.</li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    {shops.latitude && shops.longitude &&
                      <div className="map-single">
                        <h3 className="aside-title">Location</h3>
                        <div style={{minHeight:"300px"}}>
                        <MapContainer/>
                        </div>
                      </div>
                    }

                  </div>
                </div>

                <div className="right-side-bar single-right-content product-right-sin">
                  <aside className="bg-effe" style={{ padding: "20px" }}>
                    <h3 className="aside-title margin-b-3">Buying </h3>
                    <span className="pos-icon">
                      <span className="fa fa-laptop"></span>
                    </span>
                  </aside>
                  <aside className="posts p-4 border">
                    <h3 className="aside-title">Retailer Information</h3>
                    <ul className="category product-page">
                      <li className="user-text"><span className="fa fa-user yelp"></span>{shops.owner}</li>
                      <li><span className="fa fa-mobile"></span> {shops.mobile}
                      </li>
                      <li><span className="fa fa-map-marker"></span> {shops.address}
                      </li>
                      {shops.latitude && shops.longitude &&
                        <li><a href="#." className="colors"><span
                          className="fa fa-google"></span>View in Map</a>
                        </li>
                      }
                    </ul>
                  </aside>

                  {shops.office_no && shops.office_no > 0 &&
                    <aside className="bg-effe bg-effe-2">
                      <h3 className="aside-title margin-b-3"><a href="tel:+44-000-888-999">{shops.office_no}</a> </h3>
                      <p className="para-calls">Call to our service center.</p>
                      <span className="pos-icon pos-icon-2">
                        <span className="fa fa-phone"></span>
                      </span>
                    </aside>
                  }

                  <aside className="posts p-4 border">
                    <h3 className="aside-title">Shop Timings</h3>
                    {(shops.office_no) ?
                      <div dangerouslySetInnerHTML={{ __html: shops.timing }}></div>
                      :
                      <p>Not Available</p>}
                  </aside>

                  <aside className="posts p-4 border actions">
                    <h3 className="aside-title">Ad Action</h3>
                    <ul className="links-single">
                      <li><a href="#."><span className="fa fa-whatsapp"></span>Share</a></li>
                      <li><a href="#." onClick={(event) => { event.preventDefault(); event.persist(); addFavorite(event, shops.id) }}><span className={(shops.store) ? "fa fa-heart" : 'fa fa-heart-o'}></span>Favorite</a></li>
                      <li><a href="#." onClick={(event) => { event.preventDefault(); event.persist(); moveFraud() }} title='Report against shop'><span className="fa fa-flag-o"></span> Report </a></li>
                    </ul>
                  </aside>
                  <aside className="adertise-sec">
                    <div className="position-relative top-sec">
                      <img src="assets/images/advertise.jpg" alt="" className="img-fluid img-responsive" />
                      <div className="post-sec">
                        <h3 className="aside-title mb-3">Traditional <span>Sofa Collection</span></h3>
                        <p className="offer-sec">25% Off</p>
                        <a href="#." className="button-2 btn">See Details</a>
                      </div>
                    </div>
                  </aside>
                </div>
              </div>
            </div>
          </div>
        }
      </section>
      <Footer />
    </>
  )
}
