import React, { useState, useEffect } from 'react'
import { ToastContainer, toast } from 'react-toastify';
import {useNavigate } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import Loading from '../common/Loading';
import Api from '../common/Api.js'
import jwt_encode from 'jwt-encode'
import jwt_decode from "jwt-decode";
import Footer from '../common/Footer'
import Header from '../common/Header'
import InfiniteScroll from "react-infinite-scroll-component";

export default function News() {
  const navigate = useNavigate();
  const [news, setNews] = useState(null);
  const [offset, setOffset] = useState(0);
  const [count, setcount] = useState(0);
  const [categories, setCategories] = useState(null);
  const [pageloading, setpageLoading] = useState(false);
  const [searchCat, findCategory] = useState(0);
  const [counter, setCounter] = useState(0)
  const [cat_name, setCatName] = useState(null);

  useEffect(() => {
    const fetchdata = async () => {
      setpageLoading(true);
      await Api.get(`news/news/0`)
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            // result.data.errors;
            let data_error = result.data.errors;
            setpageLoading(false);
            setNews(null);
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            setpageLoading(false);
            setCategories(result.data.data.categories);
            setNews(result.data.data.news);
            setcount(result.data.data.count);
          }
        })
    };
    fetchdata();
  }, []);

  const handleCategory = (event) => {
    event.preventDefault();
    event.persist();
    let category = event.target.dataset.category;
    findCategory(category);
    getdata(category, 0);
  }

  const handleChange = (event) => {
    event.preventDefault();
    event.persist();
    let category = event.target.value;
    findCategory(category);
    getdata(category, 0);
  }

  const moveSingle = (news_id) => {
    navigate('/news-read',{state:{id:news_id}});
  }

  const getdata = async (category, offset = 0) => {
    const data = { category: category }
    setpageLoading(true);
    await Api.post('news/news/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
      .then(res => {
        const result = jwt_decode(res.data);
        const status = result.data.status;
        if (status === 'failure') {
          // result.data.errors;
          let data_error = result.data.errors;
          setpageLoading(false);
          setNews(0);
          setcount(0);
          setCatName(result.data.data.category);
          toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
        } else if (status === 'success') {
          setpageLoading(false);
          //let data = shops.concat();
          setNews(result.data.data.news);
          setcount(result.data.data.count);
          setCatName(result.data.data.category);
          setCounter(0);
          window.scrollTo(0, 0);
        }
      });
  };

  const fetchmore = async () => {
    let offset = counter + 1;
    const data = { category: searchCat }
    setTimeout(async () => {
      await Api.post('news/news/' + offset, jwt_encode(data, process.env.REACT_APP_SECRET_KEY))
        .then(res => {
          const result = jwt_decode(res.data);
          const status = result.data.status;
          if (status === 'failure') {
            let data_error = result.data.errors;
            toast.error(data_error.msg, { position: "bottom-right", autoClose: 5000, hideProgressBar: false, closeOnClick: true, pauseOnHover: true, draggable: true, progress: undefined, });
          } else if (status === 'success') {
            //setpageLoading(false);
            let data = news.concat(result.data.data.news);
            setNews(data);
            setCounter(offset);
          }
        });
    }, 1500);
  }

  return (
    <>
      <Header />
      <ToastContainer />
      <section className="w3l-blog-single">
        <div className="single blog">
          <div className="wrapper">
            <h3 className="title-main">{(cat_name !== null) ? cat_name + ' News' : 'News'}</h3>
            <aside className="top-border d-flex" style={{padding:"12px 10px 12px 10px"}}>
              {/* <h3 className="aside-title mb-3">Showing 1–{shops.length} of {count} results</h3> */}
              <div className="input-group-btn">
                <select className="btn btn-default" name="ext" value={searchCat} onChange={handleChange} style={{ textAlign: 'justify' }}>
                  <option value="0">Sort By Categories</option>
                  {
                    categories && categories.map((category, catIndex) =>
                      <option key={catIndex} value={category.id}>{category.name} ({category.count})</option>
                    )
                  }

                </select>
              </div>
            </aside>
            <div className="d-grid grid-colunm-2">
              {pageloading?<Loading/>:(news !== null && news !== 0 && news.length > 0) ?
                <InfiniteScroll
                  dataLength={news.length}
                  next={fetchmore}
                  hasMore={news.length != count}
                  endMessage={
                    <p className="alert alert-success mt-4" style={{ textAlign: 'center' }}>
                      <b>Yay! You have seen it all</b>
                    </p>
                  }
                  loader={<Loading />}
                  scrollableTarget="scrollableDiv"
                >
                  <div className="single-left">
                    {news.map((data, index) =>
                      <div className="single-left1" key={index}>
                        <a href="#." onClick={(e)=>{e.preventDefault(); e.persist();moveSingle(data.id)}}><img src={data.image} alt=" "
                          className="img-responsive img-fluid" /></a>
                        <div className="btom-cont">
                          <h5 className="card-title" style={{ float: 'unset' }} ><a href="#.">{data.title}</a></h5>
                          <ul className="admin-post">
                            <li>
                              <a href="#."><span className="fa fa-user"></span> Admin</a>
                            </li>
                            <li>
                              <a href="#."><span className="fa fa-clock-o"> {data.created_at}</span></a>
                            </li>
                            <li>
                              <a href="#."><span className="fa fa-comments-o"></span>Comments {data.comments}</a>
                            </li>
                          </ul>
                          <p className="">{data.news.toString().replace(/(<([^>]+)>)/ig, '').trim().slice(0, 250)}...</p>
                          <a href="#." onClick={(e)=>{e.preventDefault(); e.persist();moveSingle(data.id)}} className="button-eff btn">Read post</a>
                        </div>
                      </div>
                    )
                    }
                  </div>
                </InfiniteScroll>
                :
                <div className="single-left">
                  <p style={{ textAlign: 'center' }}><b>News Not found !!!</b></p>
                </div>
              }
              <div className="right-side-bar">
                <aside className="posts p-4 border">
                  <h3 className="aside-title">All Categories</h3>
                  <ul className="category">
                    <li><a href={"#!"} data-category={'All'} onClick={handleCategory}><span className="fa fa-asterisk"></span>All</a>
                    </li>
                    {
                      categories && categories.map((category, catIndex) =>
                        <li key={catIndex}><a href='#.' data-category={category.id} onClick={handleCategory}><span className={category.icon}></span>{category.name} <label>
                          ({category.count})</label></a>
                        </li>)
                    }
                  </ul>
                </aside>

                <aside className="posts p-4 border">
                  <h3 className="aside-title">Popular Shops</h3>
                  <div className="posts-grid">
                    <a href="#.">
                      <img src="assets/images/c1.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/c2.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/c3.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/c4.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/c5.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                    <a href="#.">
                      <img src="assets/images/c6.jpg" alt=" " className="img-responsive img-thumbnail" />
                    </a>
                  </div>
                </aside>

              </div>

            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  )
}
